import { FC, useState } from 'react'
import { Form, Input, Modal, Alert, Spin, notification, Tooltip, Button } from 'antd';
import { withTranslation, WithTranslation } from 'react-i18next';
import { nameof } from 'src/core/utils/object';
let FormItem = Form.Item;
import HttpService from 'src/core/services/http.service';
import { container } from 'src/inversify.config';
import { CheckOutlined, InfoCircleOutlined } from '@ant-design/icons';

interface ChangePasswordProps {
    onClose: () => void;
    userId: string;
}

interface UserPaswordChange {
    password: string,
    confirmPassword: string
}

const ChangePasswordView: FC<ChangePasswordProps & WithTranslation> = (props) => {
    const { t } = props;
    const [passwordConfirmError, setPasswordConfirmError] = useState(false);
    const [isBusy, setIsBusy] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [form] = Form.useForm<UserPaswordChange>();
    const httpService = container.get(HttpService)


    const onChangePassword = async () => {
        setErrorMessage(undefined);
        setPasswordConfirmError(false);
     
      let item: UserPaswordChange
      try {
          item = (await form.validateFields()) as UserPaswordChange

      } catch {
        return
      }

      if (item.password != item.confirmPassword) {
            setPasswordConfirmError(true);
        }
      else {
        setIsBusy(true);

            try {
                const result = await httpService.post(`api/v1/admin/users/change-password/${props.userId}`,
                  item)
              console.log(result);
                setPasswordConfirmError(false);
                setIsBusy(false);
                props.onClose();
              notification['success']({
                message: t("Changes have been saved"),
                icon: <CheckOutlined style={{ color: '#108ee9' }} />,
              });
            } catch (error) {
                setErrorMessage(error as any);
                setPasswordConfirmError(false);
                setIsBusy(false);
            }
        }
    }

    const onPasswordModalCancel = () => {
        props.onClose();
    }

    const PasswordRequeriments = (
        <div>
            <ul>
                <li>{t('At least 12 characters long.')}</li>
                <li>{t('At least one uppercase letter, one lowercase letter, one number, and one symbol.')}</li>
                <li>{t('Password must be changed every 90 days.')}</li>
                <li>{t('Cannot reuse any of the last 7 passwords.')}</li>
            </ul>
        </div>
    );
    return (
        <Modal title={t('Change password')}
            visible
            closable={false}
            maskClosable={false}
            onOk={onChangePassword}
            onCancel={onPasswordModalCancel}>
            {passwordConfirmError && <Alert message={t('The passwords you entered do not match')} type="error" />}
            {errorMessage && <Alert message={errorMessage} type="error" />}
            <Spin spinning={isBusy}>
                <Tooltip title={PasswordRequeriments} placement="left" style={{ marginBottom: '20px' }}>
                    <InfoCircleOutlined /> {t('Password Requirements')}
                </Tooltip>
                <Form form={form} layout={"vertical"}>
                    <FormItem label={t('New password')} rules={[{ required: true, message: t('Please input the new password!') }]} name={nameof<UserPaswordChange>('password')}>
                        <Input.Password placeholder={t('New password')} />
                    </FormItem>
                    <FormItem label={t('Confirm password')} name={nameof<UserPaswordChange>('confirmPassword')} rules={[{ required: true, message: t('Please input the password confirmation!') }]}>
                        <Input.Password placeholder={t('Confirm password')} style={{ marginTop: '10px' }} />
                    </FormItem>
                </Form>
            </Spin>
        </Modal>)
}

export default withTranslation()(ChangePasswordView);
