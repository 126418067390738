import * as React from 'react'
import { useEffect, useState } from 'react'
import { Form, Button, Input, Checkbox, Alert, Modal, Spin, message, Space } from 'antd'
import HttpService from 'src/core/services/http.service'
import * as queryString from 'query-string'
import { withRouter, RouteComponentProps } from 'react-router'
import QueueAnim from 'rc-queue-anim'
import { withTranslation, WithTranslation } from 'react-i18next'
import LoginButton from './login-button'
import { CodeOutlined, LockOutlined, UserOutlined } from '@ant-design/icons'
import { BaseAppUrl, container } from '../../inversify.config'
import ChangePasswordExpired from './changePasswordExpired'
import { error } from 'console'
import CountDown from 'count-down-react'

export interface LoginLocalProps extends WithTranslation, RouteComponentProps {
  children?: React.ReactNode
  form: any
  loginOnChange: any
}

export interface LoginLocalState {
  errors: string[]
  success: boolean
  busy: boolean
  externalLogin: boolean
}

const LoginLocalView: React.FC<LoginLocalProps> = (props) => {
  const { t, loginOnChange } = props
  const httpService = container.get(HttpService)
  const [errors, setErrors] = useState([])
  const [success, setSuccess] = useState(false)
  const [busy, setBusy] = useState(false)
  const [externalLogin, setExternalLogin] = useState(false)
  const [form] = Form.useForm<any>()
  const FormItem = Form.Item

  const [isChangingPassword, setIsChangingPassword] = useState(false)
  const [isTwoValidateAuthentication, setIsTwoValidateAuthentication] = useState(false)
  const [userPasswordExpired, setUserPasswordExpired] = useState()
  const [token, setToken] = useState()
  const [showMessagePasswordExpired, setSetshowMessagePasswordExpired] = useState()
  const [messageApi, contextHolder] = message.useMessage()
  const [code, setCode] = useState("")
  const [targetTime, setTargetTime] = useState(0)
  const [enableResend, setenableResend] = useState(false)
  const [json, setJson] = useState({userName : "" , password : "", rememberPassword : true })
  const [expiredTime, setExpiredTime] = useState()

  const checkExternalUser = (changeEvent: any) => {
    const email: string = changeEvent.target.value
    const domain = email.split('@')
    if (domain.length === 2) {
      if (domain[1] === 'fi-group.com' || domain[1].includes('f-iniciativas')) {
        setExternalLogin(true)
        return
      }
    }
    if (externalLogin) setExternalLogin(false)
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault()
    try {

      var values = await form.validateFields()
      setBusy(true)
      var json = {
        userName: values.userName,
        password: values.password,
        rememberLogin: values.remember,
        returnUrl: ''
      }
      var result = await httpService.post<any, any>(`${BaseAppUrl}/api/v1/account/login`, json)

      setenableResend(false)
      setExpiredTime((Date.now() + 300000) as any)
      if (!result.data.success) {
        setSuccess(false)
        setErrors(result.data.errors)

        if (result.data.changePassword == true) {
          setIsChangingPassword(true)
          setUserPasswordExpired(values.userName)
          setToken(result.data.token)
          form.resetFields()
        }
        if (result.data.twoAuthentificationRequired == true) {
          setJson(json as any)
          setIsTwoValidateAuthentication(true)
          setToken(result.data.token)
        }
      }
      else {
        handleChange(result.data.success)
        setSuccess(true)
        setErrors([])
        const parsed = queryString.parse(window.location.search || '')
        const returnUrl = result.data.redirectTo ? result.data.redirectTo : parsed.ReturnUrl || parsed.returnUrl || parsed.returnurl
        if (returnUrl) {
          window.location.href = returnUrl as string
        } else {
          window.location.href = `${BaseAppUrl}`
        }
      }
      setBusy(false)
    } catch (error) {
      setSuccess(false)
      setErrors([t("Unknow Error , please refresh the page")])
      setBusy(false)
      return
    }
  }

  useEffect(() => {
    if (showMessagePasswordExpired) {
      messageApi.open({
        type: 'success',
        content: t('Password has been successfully reseted'),
      })
    }

  }, [showMessagePasswordExpired])


  const changePassword = async () => {
    try {
      var userName = form.getFieldValue('userName')
      if (!userName || userName == ' ') {
        Modal.warning({
          title: props.t('Email missing'),
          content: (
            <div>
              <p>{props.t('Please enter your email before continue with the password reset.')}</p>
            </div>
          ),
          onOk() { }
        })
        return
      }
      Modal.confirm({
        title: props.t('Reset your password'),
        content: `${props.t('Are you sure want to reset your password? An email will be sent to')} ${userName}`,
        onOk() {
          httpService
            .post<any, any>(`${BaseAppUrl}/api/v1/account/resetPassword`, {
              username: userName,
              language: props.i18n.language
            })
            .then((result) => {
              if(result.data.success == false) {
                Modal.error({
                  title: props.t('Error'),
                  content: (                    
                    <div>
                      {result.data.errors?.map((error) =><p>{t(error)}</p>)}
                    </div>
                  ),
                  onOk() { }
                })
              }
              else{
                  Modal.info({
                    title: props.t('Done'),
                    content: (
                      <div>
                        <p>{props.t('Please check your email inbox. We have sent instructions on how reset your password.')}</p>
                      </div>
                    ),
                    onOk() { }
                  })
                }
              })
            .catch((error) => {
              if (error.response && !error.response.data.success) {
                setSuccess(false)
                setErrors(error.response.data.errors || [error.response.data.title])
                return
              }
              Modal.error({
                title: props.t('Error'),
                content: `${t(error)}. ${t("Please verify your email.")}`
              })
            })
        },
        onCancel() { }
      })
    } catch {
      return
    }
  }

  const validateCodeAsync = async (e: any) => {
    e.preventDefault()
    try {
      setBusy(true)
      var result = await httpService.post<any, any>(`${BaseAppUrl}/api/v1/account/verifyCode`, {
        token: token,
        userName: json.userName,
        password: json.password,
        rememberPassword: json.rememberPassword,
        code: code,
        
      })

      if (!result.data.success) {
        setSuccess(false)
        setErrors(result.data.errors)

      }
      else {
        setSuccess(true)
        setErrors([])
        const parsed = queryString.parse(window.location.search || '')

        window.location.href = `${BaseAppUrl}`
      }
      setBusy(false)
    } catch (error) {
      setSuccess(false)
      return
    }
  }

  const resendCodeAsync = async (e: any) => {
    var result = await httpService.post<any, any>(`${BaseAppUrl}/api/v1/account/login`, json)
    if (result.data.twoAuthentificationRequired == true) {
      setJson(json as any)
      setIsTwoValidateAuthentication(true)
      setToken(result.data.token)
      setExpiredTime((Date.now() + 300000) as any)
      setenableResend(false)
      setErrors([])
      setExpiredTime((Date.now() + 300000) as any)
    }
  }
  const CoundownRenderer = ({ days, hours, minutes, seconds }) => {
    if (minutes == 0 && seconds == 0) {
      setenableResend(true)
      setErrors([])
    }

    return <div className="customCountDown">
      <ul>
        <li>
          <span id="minutes">{minutes}</span>{t('Minutes')}
        </li>
        <li>
          <span id="seconds">{seconds}</span>{t('Seconds')}
        </li>
      </ul>
    </div>
  }


  const handleChange = e => { loginOnChange(e) }
  return (
    <>
      {contextHolder}
      <Spin spinning={busy}>

        {isChangingPassword ?
          <ChangePasswordExpired
            user={userPasswordExpired}
            token={token}
            setIsChangingPassword={setIsChangingPassword}
            messageConfirm={setSetshowMessagePasswordExpired}
          /> :
          <>
            {isTwoValidateAuthentication ?
              <>
                {!enableResend && <Alert message={t("A code was sent to your Email, please type code for login. The code will expire in 5 minutes")} type="warning" />}
                {enableResend && <Alert message={t("Code expired!")} type="error" />}
                <QueueAnim animConfig={[{ opacity: [1, 0], translateY: [0, 50] }]}>
                  {errors && errors.length > 0 && (
                    <>
                      {errors?.map((error) =>
                        <Alert key={error} message={t(error)} type="error" showIcon />
                      )}
                    </>
                  )}
                </QueueAnim>

                <Input prefix={<CodeOutlined />} placeholder={"XXXXX"} value={code} onChange={(value) => setCode(value.target.value)} style={{ marginBottom: 10}}/>
                {!enableResend && <CountDown date={expiredTime} renderer={CoundownRenderer} />}
                {enableResend && <Button type="primary" style={{ width: '100%', height: 40, marginBottom: 10 }} onClick={resendCodeAsync}>
                  {t("Resend Code")}
                </Button>}
                {!enableResend && <Button type="primary" style={{ width: '100%', height: 40, marginBottom: 10 }} onClick={validateCodeAsync}>
                  {t('Submit')}
                </Button>}
                <br />
                <Button danger type="primary" style={{ width: '100%', height: 40 }} onClick={() => setIsTwoValidateAuthentication(false)}>
                  {t('Cancel')}
                </Button>
              </>
              :
              <Form form={form} autoComplete="off" className="login-form">
                {success ? <Alert message={t('Login has successfully ended')} type="success" showIcon /> :
                  <>
                    <QueueAnim animConfig={[{ opacity: [1, 0], translateY: [0, 50] }]}>
                      {errors && errors.length > 0 && (
                        <>
                          {errors?.map((error) =>
                            <Alert key={error} message={t(error)} type="error" showIcon />
                          )}
                        </>
                      )}
                    </QueueAnim>

                    <Form.Item name={'userName'} rules={[{ required: true, message: t('Enter your username') }]}>
                      <Input prefix={<UserOutlined />} placeholder={t('User')} onChange={checkExternalUser} />
                    </Form.Item>

                    {!externalLogin ?
                      <>
                        <FormItem name={'password'} rules={[{ required: true, message: t('Enter your password') }]}>
                          <Input autoComplete="off" style={{ marginTop: 20 }} prefix={<LockOutlined />} type="password" placeholder={t('Password')} />
                        </FormItem>

                        <FormItem>
                          <a style={{ fontSize: '14px' }} onClick={changePassword}>{t('Forgot your password?')}</a>
                        </FormItem>

                        <FormItem initialValue={true} valuePropName="checked" name={'remember'} style={{ marginBottom: '14px' }}>
                          <Checkbox>{t('Remember me')}</Checkbox>
                        </FormItem>

                        <FormItem style={{ textAlign: 'center' }}>
                          <Button type="primary" htmlType="submit" style={{ width: '100%', height: 40 }} onClick={handleSubmit}>
                            {t('Log in')}
                          </Button>
                        </FormItem>
                      </>
                      :
                      <div style={{ marginTop: '10px', textAlign: 'center' }}>
                        <p>{t('Fi Group users have to connect through Office 365 professional account')}</p>
                        <LoginButton provider="Microsoft" />
                      </div>
                    }
                  </>
                }
              </Form>
            }
          </>
        }
      </Spin>

    </>
  )
}

export default withTranslation()(withRouter(LoginLocalView) as any)