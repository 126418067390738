import { Card, Skeleton, Col, Row, DatePicker } from 'antd'
import * as React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { FC, useMemo, useState } from 'react'
import logo from '../core/assets/logo.png'
import { IdentityProps, withIdentity } from '../../core/services/authentication'
import { container } from '../../inversify.config'
import { DashBoardStore } from '../../stores/dashBoard/dashBoard-store'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
} from 'chart.js'
import { Bar, Line } from 'react-chartjs-2'
import moment from 'moment'
import { SettingOutlined, SyncOutlined, ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons'
import { loadPage } from '../../core/utils/nav'

const { RangePicker } = DatePicker

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
)


export interface ExternalApiAccessProps extends WithTranslation, IdentityProps {
  text: string
}

const ExternalApiAccess: FC<ExternalApiAccessProps> = ({
  identity,
  t,
  text,
  children,
}) => {

  const [loading, setIsLoading] = useState<boolean>(true)
  const currentStore = useMemo(() => container.get(DashBoardStore), [])
  const currentState = currentStore.state
  const [data, setData] = useState<any>()
  const [zoom, setZoom] = useState(false)
  const [startDateFilter, setStartDateFilter] = useState<moment.Moment | null>(moment().startOf('day'))
  const [endDateFilter, setEndDateFilter] = useState<moment.Moment | null>(moment())

  const LoadPage = async () => {
    const startDateFormatted = moment(startDateFilter).format("DD-MM-YYYY HH:mm:ss")
    const endDateFormatted = moment(endDateFilter).format("DD-MM-YYYY HH:mm:ss")
    let chart1 = await currentStore.ExternalApiAccess(startDateFormatted, endDateFormatted)
    let labels = chart1.map(x => x.key)

    const current = {
      labels,
      datasets: [
        {
          label: t('User Access'),
          data: chart1.map(x => x.value),
          borderColor: '#e53935',
          backgroundColor: '#e53935',
        },

      ],
    }
    setData(current)
    setIsLoading(false)
  }

  React.useEffect(
    () => {
      LoadPage()
    }, [startDateFilter, endDateFilter]
  )

  const options = {
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      legend: {
      },
      title: {
        display: true,
        text: `${t('Access Per User. Date Range')}: ${moment(startDateFilter).format("DD-MM-YYYY HH:mm:ss")} - ${moment(endDateFilter).format("DD-MM-YYYY HH:mm:ss")}`,
      },
    },
  }

  const Refresh = async () => {
    setIsLoading(true)
    await LoadPage()
  }

    const onDateRangeFilterChange = (dates: any) => {
        var [startDate, endDate] = [null, null]
        if (dates) {
            [startDate, endDate] = dates
        }
        setStartDateFilter(startDate)
        setEndDateFilter(endDate)
    }

  return (
    <>
      <div className={"report-container"} >
        <Card
          style={{}}
          actions={
            !zoom ? [
              <ZoomInOutlined onClick={() => setZoom(!zoom)} key="zoom" />,
              <SyncOutlined onClick={() => Refresh()} key="refresh" />


            ] :
              [
                <ZoomOutOutlined onClick={() => setZoom(!zoom)} key="zoom" />,
                <SyncOutlined onClick={() => Refresh()} key="refresh" />
              ]
          }
        >
          <Skeleton loading={loading} avatar active>
            {data && <Bar options={options} data={data as any} />}
          </Skeleton>
        </Card>
      </div>
      {zoom && <div className={zoom ? "report-fullscreen report-container opacity-1" : "report-fullscreen report-container opacity-0"} >
        <Card
          style={{}}
          actions={
            !zoom ? [
              <ZoomInOutlined onClick={() => setZoom(!zoom)} key="zoom" />,
              <SyncOutlined onClick={() => Refresh()} key="refresh" />


            ] :
              [
                <ZoomOutOutlined onClick={() => setZoom(!zoom)} key="zoom" />,
                <SyncOutlined onClick={() => Refresh()} key="refresh" />
              ]
          }
        >
        <Row gutter={50}>
           <Col span={12}>
               <label><strong>{t("Select a Date Range")} </strong></label>
                <RangePicker
                    renderExtraFooter={() => t("Select a date...")}
                    style={{ width: 350 }}
                    format='DD-MM-YYYY HH:mm:ss'
                    showTime
                    onChange={(dates) => onDateRangeFilterChange(dates)}
                    placeholder={[t('Start date'), t('End date')]}
                    value={[startDateFilter, endDateFilter]}
                    disabledDate={(current) => {
                        if (startDateFilter && !endDateFilter) {
                            return current && current < moment(startDateFilter)
                        } else if (!startDateFilter && endDateFilter) {
                            return current && current > moment(endDateFilter)
                        }
                        return false
                    }}
                />
           </Col>
        </Row>
          <Skeleton loading={loading} avatar active>
            {data && <Bar options={options} data={data as any} />}
          </Skeleton>
        </Card>
      </div>
      }
    </>
  )

}


export default withIdentity(withTranslation()(ExternalApiAccess) as any)
