import { useState, useEffect } from 'react'
import { Button, Avatar } from 'antd'
import { withTranslation, WithTranslation } from 'react-i18next'
import MicrosoftIcon from 'src/core/assets/images/login/microsoft-icon.png'
import GoogleIcon from 'src/core/assets/images/login/google-icon.png'
import FacebookIcon from 'src/core/assets/images/login/facebook-icon.png'
import LoginIcon from '../../assets/login-icon.png'
import { BaseAppUrl } from '../../inversify.config'

interface LoginButtonProps extends WithTranslation {
    provider: 'Local' | 'Microsoft' | 'Facebook' | 'Google'
    email?: string,
    primary?: boolean,
    onLocalLogin?: () => void
}

function getReturnUri() {
    try {
        return (new URLSearchParams((new URLSearchParams(window.location.search)).get("ReturnUrl") || "")).get("redirect_uri") || ""
    } catch {
        return ""
    }
}

export const LoginButton: React.FC<LoginButtonProps> = (props) => {
    const { t } = props
    const [avatarSrc, setAvatarSrc] = useState<any>(MicrosoftIcon)
    const [title, setTitle] = useState('Local User')

    const handleClick = () => {
        if (props.provider == 'Local' && props.onLocalLogin)
            props.onLocalLogin()
        if (props.provider == 'Microsoft') {
            window.location.href = `${BaseAppUrl}/api/v1/account/externalLogin?provider=mslive&returnUrl=${getReturnUri()}`
        }
    }

    useEffect(() => {
        switch (props.provider) {
            case 'Microsoft':
                setAvatarSrc(MicrosoftIcon)
                setTitle('Professional account (Office 365)')
                break

            case 'Google':
                setTitle('Google account')
                setAvatarSrc(GoogleIcon)
                break

            case 'Facebook':
                setTitle('Facebook account')
                setAvatarSrc(FacebookIcon)
                break
            case 'Local':
                setTitle('Local login')
                setAvatarSrc(LoginIcon)
                break
        }
    }, [props.provider]
    )

    return <Button onClick={handleClick} type={props.primary ? 'primary' : 'default'} className='login-button'>
        <Avatar size="large" icon="user" shape="square" src={avatarSrc || undefined} />
        <div>
            <b>{t(title)}</b><br />
            {props.email}
        </div>
    </Button>
}

export default withTranslation()(LoginButton)