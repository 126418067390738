import * as React from 'react'
import { content } from './cgu'
import { FC } from 'react'
import { withTranslation, WithTranslation } from "react-i18next"
import { Modal } from 'antd';


interface CguContentProps {
  onClose?: () => any
}

const CguContentView: FC<CguContentProps & WithTranslation> = (props) => {

  const [display, setDisplay] = React.useState(false)
  const { t } = props
  const close = () => {
    setDisplay(false)
    if (props.onClose)
      props.onClose()
  }



  return (
    <Modal
      onOk={() => close()}
      onCancel={() => close()}
      closable={true}
      maskClosable={false}
      title={t('Terms of use')}
      width={800}
      style={{ top: 20 }}
      footer={null}
      bodyStyle={{ height: '750px', overflowY: 'scroll' }}
      visible={true}
    >
      <div dangerouslySetInnerHTML={{ __html: content }} />
    </Modal>)
}


export default withTranslation()(CguContentView)
