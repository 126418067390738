import * as React from 'react'
import { Form, Spin, Input, Modal, Row, Col, Alert, Checkbox } from 'antd'
let FormItem = Form.Item
import autobind from 'autobind-decorator'
import { ApplicationItemStore, NewApplicationItem } from 'src/stores/applications/applications-store'
import { withTranslation, WithTranslation } from 'react-i18next'
import { nameof } from 'src/core/utils/object'
import TextArea from 'antd/lib/input/TextArea'
import { container } from '../../../inversify.config'
import { ValidationRule } from 'lakmus'
import { useState } from 'react'

interface NewApplicationViewProps {
  onClose: () => void
}



const NewApplicationView: React.FC<NewApplicationViewProps & WithTranslation> = (props) => {
  const { t } = props
  const [form] = Form.useForm<NewApplicationItem>()
  const [initialValues, setInitialValues] = React.useState<NewApplicationItem | undefined>({
    name: "",
    description: "",
    displayName: "",
    sharedSecret: "",
    enabled: true,
    redirectUris: "",
    hideInUserList:false
  })

  const currentStore = React.useMemo(() => container.get(ApplicationItemStore), [])
  const currentState = currentStore.state
  const [confirmLoading, setConfirmLoading] = useState(false);


  const onCancel = () => {
    props.onClose()
  }

  const onCreate = async () => {
    setConfirmLoading(true)
    let item: NewApplicationItem
    try {
      item = (await form.validateFields()) as NewApplicationItem
    } catch {
      setConfirmLoading(false)
      return
    }
    let response = await currentStore.create(item)
    if (response) {
      form.resetFields()
      props.onClose()
    }
    setConfirmLoading(false)
  }
  const onValidateURIs = (rule: any, value: string, callback: Function) => {
    if (value !== null) {
      var lines = value.split("/\r?\n/")
      if (lines.any(ln => ln.length > 2000))
        callback(false)
    }
    callback()
  }


  return (
    <Modal
      maskClosable={false}
      visible
      onCancel={onCancel}
      onOk={onCreate}
      closable={false}
      confirmLoading={confirmLoading}
      title={t('New application')}
      cancelText={t('Cancel')}
      okText={t('Ok')}
    >
      {currentState.errorMessage.get() && <Alert type="error" message={t(currentState.errorMessage.value || '')} />}
      <Spin spinning={currentState.isBusy.get()}>
        <Form layout="vertical" form={form} initialValues={initialValues}>
          <FormItem label={t("Application Id")}
            name={nameof<NewApplicationItem>('name')}
            rules={[
              { required: true, message: t('Please input your application id!') },
              { max: 200, message: t('Application Id must have less than 200 chars.') }
            ]}
          >

            <Input placeholder={t("Name")} />
          </FormItem>
          <FormItem label={t("Display name")}
            name={nameof<NewApplicationItem>('displayName')}
            rules={[
              { required: true, message: t('Please input your application display name!') },
              { max: 200, message: t('Display name must have less than 200 chars.') }
            ]}
          >

            <Input placeholder={t("Name")} />
          </FormItem>
          <FormItem label={t("Description")}
            name={nameof<NewApplicationItem>('description')}
            rules={[
              { required: false, message: t('Please input your application name!') },
              { max: 1000, message: t('Description must have less than 1000 chars.') }
            ]}>

            <TextArea placeholder={t("Name")} />
          </FormItem>
          <FormItem label={t("Redirect URIs")}
            name={nameof<NewApplicationItem>('redirectUris')}
            rules={[
              { required: true, message: t('Please input your redirect URIs!') },
              { validator: onValidateURIs, message: t('Each URI must have less than 2000 chars.') }
            ]}>
            <TextArea placeholder={"https://tempuri.org"} />
          </FormItem>
          <FormItem label={t("Shared secret")}
            name={nameof<NewApplicationItem>('sharedSecret')}
            rules={[{ required: true, message: t('Application shared secret is required') }]}
          >
            <Input placeholder={t("Shared secret")} />
          </FormItem>
          <small>{t("Save this string elsewhere because it will not be shown again")}</small>
          <FormItem name={nameof<NewApplicationItem>('enabled')}
            valuePropName='checked'>
            <Checkbox><span>{t("Enable the application")}</span></Checkbox>
          </FormItem>
        <FormItem name={nameof<NewApplicationItem>('hideInUserList')}
            valuePropName='checked'>
            <Checkbox><span>{t("Hide in user List")}</span></Checkbox>
        </FormItem>
        </Form>
      </Spin>
    </Modal>
  )
}
export default withTranslation()(NewApplicationView)
