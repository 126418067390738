export default {
  "source": "en",
  "targets": [
    "es",
    "fr",
    "pt",
    "it",
    "de"
  ],
  "data": {
    "en": {
      "disclaimer_text": "The personal data provided via this application is processed under a service contract between you and FI GROUP. Sensitive personal data must not be provided. In the event that you provide personal data of a sensitive nature, FI GROUP will not process such data.",
      "disclaimer_profile": "The data controller is F. INICIATIVAS ESPAÑA I MAS D MAS I S.L. and any questions or requests to exercise rights can be made by contacting the email: <a mailto:'rgpd@fi-group.com'>rgpd@fi-group. com</a> or through the form in Your profile > Exercise rights.",
      "disclaimer_specification": "It refers to personal data that reveal racial or ethnic origin, political opinions, religious or philosophical beliefs, or trade union membership, as well as the processing of genetic data, biometric data for the purpose of uniquely identifying a natural person, data concerning health, or data concerning a natural person’s sex life or sexual orientation."
      
    },
    "es": {
      "Download template": "Descargar plantilla",
      "An error ocurred": "Ha ocurrido un error",
      "Bulk upload": "Carga masiva",
      "Bulk upload items": "Elementos de carga masiva",
      "Your Profile": "Su perfil",
      "Change company": "Cambiar de empresa",
      "Close session": "Cerrar sesión",
      "Edit": "Editar",
      "Delete": "Eliminar",
      "Delete elements": "Eliminar elementos",
      "Delete selected": "Eliminar selecionado",
      "Delete All": "Eliminar todos",
      "Save": "Guardar",
      "Undo": "Deshacer",
      "Add new": "Agregar nuevo elemento",
      "to": "a",
      "of": "de",
      "Confirmation required": "Confirmación requerida",
      "Upload complete": "Carga completa",
      "Import template": "Modelo de importación",
      "Use an excel template to easily import": "Use un modelo de excel para importar facilmente",
      "download import template": "descargar modelo de importación",
      "We help you import your information in an agile way": "Te ayudamos a importar tu información de forma ágil",
      "Click or drag file to this area to upload": "Haga clic o arrastre el archivo a esta área para cargar",
      "Order by": "Ordenar por",
      "filter not configured": "filtro no configurado",
      "Clear filter": "Filtro limpio",
      "Are you sure that you want to discard these changes?": "¿Está seguro de que desea descartar estos cambios?",
      "Are you sure want to delete selected items?": "¿Está seguro que desea eliminar los elementos seleccionados?",
      "Optional Columns": "Columnas opcionales",
      "is in": "es en",
      "Show rows where": "Mostrar filas donde",
      "And": "Y",
      "Or": "O",
      "Sort Ascending": "Orden Ascendente",
      "Sort Descending": "Orden Descendente",
      "Remove Filters": "Eliminar filtros",
      "OK": "OK",
      "Cancel": "Cancelar",
      "No matches": "No hay coincidencias",
      "R&D Platform": "Plataforma R&D",
      "Timesheet": "Hoja de tiempo",
      "Input search text...": "Texto de búsqueda...",
      "No data": "No hay datos",
      "Reset filter": "Restablecer filtro",
      "Select all": "Seleccionar todo",
      "Filter confirm": "Filtro confirmar",
      "Filter": "Filtrar",
      "Sort": "Ordenar",
      "some": "cualquier",
      "every": "todos",
      "Select All Search Results": "Seleccionar todos los resultados de búsqueda",
      "Copyright": "Derechos de autor",
      "FI Group all rights reserved": "FI Group, todos los derechos reservados",
      "Terms of Use": "Términos y condiciones",
      "Data Subject Right": "Tratamientos de datos",
      "Cookies Policy": "Política de cookies",
      "Helpdesk": "Helpdesk",
      "Login": "Iniciar sesión",
      "Do you want to access these applications? Ask us!": "¿Quieres acceder a estas aplicaciones? ¡Consúltanos!",
      "It seems that you dont have access to any application. Please contact your administartor": "Al parecer no tiene acceso a ninguna aplicación. Por favor, contacte a su administrador",
      "Your session has expired.": "Su sesión ha caducado.",
      "This will redirect to Login page in": "Esto redirigirá a la página de inicio de sesión en",
      "seconds": "segundos",
      "Completed": "Terminado",
      "Remaining": "Restante",
      "Are you sure want to delete this item?": "¿Está seguro de que desea eliminar este elemento?",
      "Home": "Inicio",
      "Yes": "Sí",
      "No": "No",
      "elements deleted successful": "elementos eliminados con éxito",
      "Are you sure do you want to delete all items?": "¿Estás seguro de que quieres eliminar todos los elementos?",
      "Total elements": "Total de elementos",
      "Total imported": "Total importados",
      "Download errors file": "Descargar fichero de errores",
      "Errors": "Errores",
      "Download warnings file": "Descargar archivo de advertencias",
      "Warnings": "Advertencias",
      "Deleting...": "Eliminando...",
      "Deleted elements": "Elementos eliminados",
      "Fail deletion": "Eliminación fallida",
      "Invert selection": "Invertir selección",
      "Search": "Buscar",
      "Showing only part of the results. Search to find the one you need.": "Mostrando solo una parte de los resultados. Busca para encontrar el que necesitas.",
      "Not set": "No establecido",
      "disclaimer_text": "Los datos personales aportados mediante este aplicativo, se tratan en virtud de un contrato de servicios entre usted y FI GROUP. No se deben aportar datos personales de carácter sensible. En el caso, de que usted aporte datos personales de carácter sensible, FI GROUP no realizará ningún tratamiento sobre dichos datos.",
      "disclaimer_profile": "El responsable del tratamiento es F. INICIATIVAS ESPAÑA I MAS D MAS I S.L. y cualquier duda o solicitud de ejercicio de derechos la puede realizar dirigiéndose al correo electrónico: <a mailto:'rgpd@fi-group.com'>rgpd@fi-group.com</a> o mediante el formulario de Su perfil > Ejercer derechos.",
      "disclaimer_specification": "Se refiere a datos personales que revelen origen racial o étnico, opiniones políticas, creencias religiosas o filosóficas, o afiliación sindical y el procesamiento de datos genéticos, datos biométricos con el fin de identificar de forma única a una persona física, los datos relativos a la salud o los datos relativos a la vida sexual o la orientación sexual de una persona física.",
      "Go to Profile": "Ir a Perfil",
      "Language": "Idioma", 
      "Hi, ": "Hola, ",
      "Change password": "Cambiar la contraseña",


    },
    "fr": {
      "Download template": "Télécharger le modèle",
      "An error ocurred": "Une erreur s'est produite",
      "Bulk upload": "Téléchargement en masse",
      "Bulk upload items": "Téléchargement en masse",
      "Your Profile": "Votre profil",
      "Change company": "Changer d'entreprise",
      "Close session": "Fermer la session",
      "Edit": "Éditer",
      "Delete": "Supprimer",
      "Delete elements": "Supprimer des éléments",
      "Delete selected": "Supprimer les éléments sélectionnés",
      "Delete All": "Eliminar todos",
      "Save": "Sauver",
      "Undo": "Annuler",
      "Add new": "Ajouter un nouvel élément",
      "to": "pour",
      "of": "de",
      "Confirmation required": "Confirmation requise",
      "Upload complete": "Téléchargement complet",
      "Import template": "Modèle d'importation",
      "Use an excel template to easily import": "Utilisez un modèle Excel pour importer facilement",
      "download import template": "télécharger le modèle d'importation",
      "We help you import your information in an agile way": "Nous vous aidons à importer vos informations de manière agile",
      "Click or drag file to this area to upload": "Cliquez ou faites glisser le fichier dans cette zone pour télécharger",
      "Order by": "Commandé par",
      "filter not configured": "filtre non configuré",
      "Clear filter": "Effacer le filtre",
      "Are you sure that you want to discard these changes?": "Voulez-vous vraiment annuler ces modifications ?",
      "Are you sure want to delete selected items?": "¿Voulez-vous vraiment supprimer les éléments sélectionnés?",
      "Optional Columns": "Colonnes facultatives",
      "is in": "est dans",
      "Show rows where": "Afficher les lignes où",
      "And": "Et",
      "Or": "Ou alors",
      "Sort Ascending": "Trier par ordre croissant",
      "Sort Descending": "Trier par ordre décroissant",
      "Remove Filters": "Supprimer les filtres",
      "OK": "D'ACCORD",
      "Cancel": "Annuler",
      "No matches": "Pas de correspondance",
      "R&D Platform": "Plateforme R&D",
      "Timesheet": "Emploi du temps",
      "Input search text...": "Texte de recherche d'entrée...",
      "No data": "Pas de données",
      "Reset filter": "Réinitialiser le filtre",
      "Select all": "Tout sélectionner",
      "Filter confirm": "Confirmation du filtre",
      "Filter": "Filtre",
      "Sort": "Trier",
      "some": "certains",
      "every": "tous",
      "Select All Search Results": "Sélectionner tous les résultats de la recherche",
      "Copyright": "Droits d'auteur",
      "FI Group all rights reserved": "FI Group, tous droits réservés",
      "Terms of Use": "Mentions légales",
      "Data Subject Right": "Démarche données personnelles",
      "Cookies Policy": "Politique de confidentialité",
      "Helpdesk": "Helpdesk",
      "Login": "Commencer la session",
      "Do you want to access these applications? Ask us!": "Vous souhaitez accéder à ces applications ? Demandez nous!",
      "It seems that you dont have access to any application. Please contact your administartor": "Il semble que vous n'ayez pas l'autorisation d'énumérer des documents dans une entreprise. Si vous pensez que cela est incorrect, veuillez contacter votre administrateur",
      "Your session has expired.": "Votre session a expiré.",
      "This will redirect to Login page in": "Cela redirigera vers la page de connexion dans",
      "seconds": "seconds",
      "Completed": "complété",
      "Remaining": "Restant",
      "Are you sure want to delete this item?": "Voulez-vous vraiment supprimer cet élément ?",
      "Home": "Début",
      "Yes": "Oui",
      "No": "Non",
      "elements deleted successful": "éléments supprimés avec succès",
      "Are you sure do you want to delete all items?": "Voulez-vous vraiment supprimer tous les éléments ?",
      "Total elements": "Éléments totaux",
      "Total imported": "Total importé",
      "Download errors file": "Télécharger le fichier d'erreurs",
      "Errors": "Erreurs",
      "Download warnings file": "Télécharger le fichier d'avertissements",
      "Warnings": "Mises en garde",
      "Deleting...": "Suppression...",
      "Deleted elements": "Éléments supprimés",
      "Fail deletion": "Échec de la suppression",
      "Invert selection": "Inverser la sélection",
      "Search": "Rechercher",
      "Showing only part of the results. Search to find the one you need.": "Affichage d'une partie seulement des résultats. Cherchez pour trouver celui dont vous avez besoin.",
      "Not set": "Pas encore défini",
        "disclaimer_text": "Les données personnelles fournies via cette application sont traitées dans le cadre d'un contrat de service entre vous et FI GROUP. Les données personnelles sensibles ne doivent pas être fournies. Si vous fournissez des données personnelles de nature sensible, FI GROUP ne les traitera pas.",
      "disclaimer_profile": "Le responsable du traitement est F. INICIATIVAS ESPAÑA I MAS D MAS I S.L. et toute question ou demande d'exercice des droits peut être faite en contactant l'email : <a mailto:'rgpd@fi-group.com'>rgpd@fi-group.com </a> ou via le formulaire dans Votre profil > Exercer les droits.",
      "disclaimer_specification": "Il s'agit des données personnelles révélant l'origine raciale ou ethnique, les opinions politiques, les convictions religieuses ou philosophiques ou l'appartenance syndicale et le traitement de données génétiques, de données biométriques en vue d'identifier de manière unique une personne physique, de données relatives à la santé ou de données relatives à à la vie sexuelle ou à l’orientation sexuelle d’une personne physique.",
      "Go to Profile": "Aller au Profil",
      "Language": "Langue",
      "Hi, ": "Bonjour, ",
      "Change password": "Changer son mot de passe",

    },
    "pt": {
      "Download template": "baixar modelo",
      "An error ocurred": "Um erro ocorreu",
      "Bulk upload": "Upload em massa",
      "Bulk upload items": "Upload de itens em massa",
      "Your Profile": "Seu perfil",
      "Change company": "Mudar de empresaa",
      "Close session": "Fechar sessão",
      "Edit": "Editar",
      "Delete": "Excluir",
      "Delete elements": "Excluir elementos",
      "Delete selected": "Apagar selecionado",
      "Delete All": "Excluir tudo",
      "Save": "Salvar",
      "Undo": "Desfazer",
      "Add new": "Adicionar novo",
      "to": "para",
      "of": "de",
      "Confirmation required": "Necessária confirmação",
      "Upload complete": "Envio completo",
      "Import template": "Modelo de importação",
      "Use an excel template to easily import": "Use um modelo de excel para importar facilmente",
      "download import template": "baixar modelo de importação",
      "We help you import your information in an agile way": "Ajudamos você a importar suas informações de forma ágil",
      "Click or drag file to this area to upload": "Clique ou arraste o arquivo para esta área para fazer o upload",
      "Order by": "Ordenar por",
      "filter not configured": "filtro não configurado",
      "Clear filter": "Filtro limpo",
      "Are you sure that you want to discard these changes?": "Tem certeza de que deseja descartar essas alterações?",
      "Are you sure want to delete selected items?": "Tem certeza que deseja excluir os itens selecionados?",
      "Optional Columns": "Colunas Opcionais",
      "is in": "é em",
      "Show rows where": "Mostrar linhas onde",
      "And": "E",
      "Or": "Ou",
      "Sort Ascending": "Ordernar Ascendente",
      "Sort Descending": "Ordenar Descendente",
      "Remove Filters": "Remover filtros",
      "OK": "OK",
      "Cancel": "Cancelar",
      "No matches": "Sem combinações",
      "R&D Platform": "Plataforma de R&D",
      "Timesheet": "Planilha de horário",
      "Input search text...": "Insira texto de pesquisa",
      "No data": "Sem dados",
      "Reset filter": "Redefinir filtro",
      "Select all": "Selecionar tudo",
      "Filter confirm": "Filtrar confirmação",
      "Filter": "Filtro",
      "Sort": "Ordenar",
      "some": "alguns",
      "every": "todos",
      "Select All Search Results": "Selecionar todos os resultados da pesquisa",
      "Copyright": "Copyright",
      "FI Group all rights reserved": "FI Group, todos os direitos reservados",
      "Terms of Use": "Termos e Condições",
      "Data Subject Right": "Politica de Privacidade",
      "Cookies Policy": "Política de cookies",
      "Helpdesk": "Helpdesk",
      "Login": "Iniciar sessão",
      "Do you want to access these applications? Ask us!": "Você deseja acessar esses aplicativos? Pergunte-nos!",
      "It seems that you dont have access to any application. Please contact your administartor": "Parece que não tem acesso a nenhuma aplicação. Por favor, contacte o administrador",
      "Your session has expired.": "Sua sessão expirou.",
      "This will redirect to Login page in": "Isso redirecionará para a página de login em",
      "seconds": "segundos",
      "Completed": "Concluído",
      "Remaining": "Restante",
      "Are you sure want to delete this item?": "Tem certeza de que deseja excluir este item?",
      "Home": "Começo",
      "Yes": "Sim",
      "No": "Não",
      "elements deleted successful": "elementos deletados com sucesso",
      "Total elements": "Elementos totais",
      "Total imported": "Total importado",
      "Download errors file": "Baixar arquivo de erros",
      "Errors": "Erros",
      "Download warnings file": "Baixar arquivo de avisos",
      "Warnings": "Avisos",
      "Deleting...": "Excluindo...",
      "Deleted elements": "Elementos excluídos",
      "Fail deletion": "Falha na exclusão",
      "Invert selection": "Seleção invertida",
      "Search": "Busca",
      "Showing only part of the results. Search to find the one you need.": "Mostrando apenas parte dos resultados. Pesquise para encontrar o que você precisa.",
      "Not set": "Não configurado",
      "disclaimer_text": "Os dados pessoais fornecidos através desta aplicação são tratados no âmbito de um contrato de prestação de serviços entre o utilizador e o FI GROUP. Os dados pessoais sensíveis não devem ser fornecidos. Caso o utilizador forneça dados pessoais de natureza sensível, a FI GROUP não tratará esses dados.",
      "disclaimer_profile": "O responsável pelo tratamento é a F. INICIATIVAS ESPAÑA I MAS D MAS I S.L. e qualquer questão ou pedido de exercício de direitos pode ser feito através do email: <a mailto:'rgpd@fi-group.com'>rgpd@fi-group.com </a> ou através do formulário em Seu perfil > Exercer direitos.",
      "disclaimer_specification": "Refere-se a dados pessoais que revelem origem racial ou étnica, opiniões políticas, crenças religiosas ou filosóficas, ou afiliação sindical, bem como o processamento de dados genéticos, dados biométricos com o objetivo de identificar de forma única uma pessoa física, dados relativos à saúde ou dados relativos à vida sexual ou orientação sexual de uma pessoa física.",
      "Go to Profile": "Ir para o Perfil",
      "Language": "Linguagem",
      "Change password": "Mudar senha",
      "Hi, ": "Olá, ",
    },
    "it": {
      "Download template": "Scarica modello",
      "An error ocurred": "Si è verificato un errore",
      "Bulk upload": "Caricamento collettivo",
      "Bulk upload items": "Caricamento collettivo elementi",
      "Your Profile": "Il tuo profilo",
      "Change company": "Cambia azienda",
      "Close session": "Chiudi sessione",
      "Edit": "Modifica",
      "Delete": "Elimina",
      "Delete elements": "Elimina elementi",
      "Delete selected": "Elimina selezionati",
      "Delete All": "Elimina tutto",
      "Save": "Salva",
      "Undo": "Annulla",
      "Add new": "Aggiungi nuovo elemento",
      "to": "a",
      "of": "di",
      "Confirmation required": "Conferma richiesta",
      "Upload complete": "Caricamento completato",
      "Import template": "Importa modello",
      "Use an excel template to easily import": "Utilizza un modello Excel per importare facilmente",
      "download import template": "scarica modello di importazione",
      "We help you import your information in an agile way": "Ti aiutiamo a importare le tue informazioni in modo agile",
      "Click or drag file to this area to upload": "Fai clic o trascina il file in quest'area per caricarlo",
      "Order by": "Ordina per",
      "filter not configured": "filtro non configurato",
      "Clear filter": "Cancella filtro",
      "Are you sure that you want to discard these changes?": "Sei sicuro di voler eliminare queste modifiche?",
      "Are you sure want to delete selected items?": "Sei sicuro di voler eliminare gli elementi selezionati?",
      "Optional Columns": "Colonne facoltative",
      "is in": "è in",
      "Show rows where": "Mostra righe dove",
      "And": "E",
      "Or": "Pregato",
      "Sort Ascending": "Ordinamento crescente",
      "Sort Descending": "Ordine decrescente",
      "Remove Filters": "Rimuovi filtri",
      "OK": "OK",
      "Cancel": "Annulla",
      "No matches": "Nessuna corrispondenza",
      "R&D Platform": "Piattaforma R&D",
      "Timesheet": "Foglio di tempo",
      "Input search text...": "Testo di ricerca...",
      "No data": "Nessun dato",
      "Reset filter": "Ripristina filtro",
      "Select all": "Seleziona tutto",
      "Filter confirm": "Conferma filtro",
      "Filter": "Filtro",
      "Sort": "Ordina",
      "some": "alcuni",
      "every": "tutti",
      "Select All Search Results": "Seleziona tutti i risultati della ricerca",
      "Copyright": "Diritto d'autore",
      "FI Group all rights reserved": "Gruppo FI, tutti i diritti riservati",
      "Terms of Use": "Termini e condizioni",
      "Data Subject Right": "Trattamento dei dati",
      "Cookies Policy": "Politica sui cookie",
      "Helpdesk": "Helpdesk",
      "Login": "Entra",
      "Do you want to access these applications? Ask us!": "Vuoi accedere a queste applicazioni? Chiedicelo!",
      "It seems that you dont have access to any application. Please contact your administartor": "Sembra che tu non abbia accesso a nessuna applicazione. Contatta il tuo amministratore",
      "Your session has expired.": "La tua sessione è scaduta.",
      "This will redirect to Login page in": "Questo reindirizzerà alla pagina di login in",
      "seconds": "secondi",
      "Completed": "Finito",
      "Remaining": "Rimanente",
      "Are you sure want to delete this item?": "Sei sicuro di voler eliminare questo elemento?",
      "Home": "inizio",
      "Yes": "Si",
      "No": "Nerd",
      "elements deleted successful": "elementi eliminati con successo",
      "Are you sure do you want to delete all items?": "Sei sicuro di voler eliminare tutti gli elementi?",
      "Total elements": "Elementi totali",
      "Total imported": "Totale importato",
      "Download errors file": "Scarica file errori",
      "Errors": "Errori",
      "Download warnings file": "Scarica il file degli avvisi",
      "Warnings": "Avvisi",
      "Deleting...": "Eliminazione...",
      "Deleted elements": "Elementi eliminati",
      "Fail deletion": "Eliminazione fallita",
      "Invert selection": "Inverti selezione",
      "Search": "Cercare",
      "Showing only part of the results. Search to find the one you need.": "Mostra solo una parte dei risultati. Cerca per trovare quello che ti serve.",
      "Not set": "Non impostato",
      "disclaimer_text": "I dati personali forniti attraverso questa applicazione sono trattati nell'ambito di un contratto di servizio tra te e FI GROUP. Non devono essere forniti dati personali sensibili. Nel caso in cui Lei fornisca dati personali di natura sensibile, FI GROUP non effettuerà alcun trattamento su tali dati.",
      "disclaimer_profile": "Il titolare del trattamento è F. INICIATIVAS ESPAÑA I MAS D MAS I S.L. e qualsiasi domanda o richiesta di esercizio dei diritti può essere fatta contattando l'e-mail: <a mailto:'rgpd@fi-group.com'>rgpd@fi-group.com </a> o tramite il modulo in Il tuo profilo > Esercizio dei diritti.",
      "disclaimer_specification": "Si riferisce a dati personali che rivelano l’origine razziale o etnica, le opinioni politiche, le convinzioni religiose o filosofiche, o l’affiliazione sindacale, nonché il trattamento di dati genetici, dati biometrici al fine di identificare in modo univoco una persona fisica, dati relativi alla salute o dati relativi alla vita sessuale o all’orientamento sessuale di una persona fisica.",
      "Go to Profile": "Vai al Profilo",
      "Language": "Lingua",
      "Change password": "Cambia la password",
      "Hi, ": "Ciao, ",
    },
    "de": {
      "Download template": "Vorlage herunterladen",
      "An error ocurred": "Ein Fehler ist aufgetreten",
      "Bulk upload": "Massen-Upload",
      "Bulk upload items": "Massen-Upload von Artikeln",
      "Your Profile": "Ihr Profil",
      "Change company": "Firma wechseln",
      "Close session": "Sitzung schließen",
      "Edit": "Bearbeiten",
      "Delete": "Löschen",
      "Delete elements": "Elemente löschen",
      "Delete selected": "Ausgewählte löschen",
      "Delete All": "Alle löschen",
      "Save": "Speichern",
      "Undo": "Rückgängig machen",
      "Add new": "Neuen Artikel hinzufügen",
      "to": "zu",
      "of": "von",
      "Confirmation required": "Bestätigung erforderlich",
      "Upload complete": "Hochladen abgeschlossen",
      "Import template": "Vorlage importieren",
      "Use an excel template to easily import": "Verwenden Sie eine Excel-Vorlage zum einfachen Importieren",
      "download import template": "Importvorlage herunterladen",
      "We help you import your information in an agile way": "Wir helfen Ihnen, Ihre Informationen agil zu importieren",
      "Click or drag file to this area to upload": "Datei zum Hochladen in diesen Bereich klicken oder ziehen",
      "Order by": "Bestellen nach",
      "filter not configured": "Filter nicht konfiguriert",
      "Clear filter": "Filter löschen",
      "Are you sure that you want to discard these changes?": "Sind Sie sicher, dass Sie diese Änderungen verwerfen möchten?",
      "Are you sure want to delete selected items?": "Sind Sie sicher, dass Sie ausgewählte Elemente löschen möchten?",
      "Optional Columns": "Optionale Spalten",
      "is in": "ist dabei",
      "Show rows where": "Zeige Zeilen wo",
      "And": "Und",
      "Or": "Betete",
      "Sort Ascending": "Aufsteigend sortieren",
      "Sort Descending": "Absteigende Reihenfolge",
      "Remove Filters": "Filter entfernen",
      "OK": "OK",
      "Cancel": "Abbrechen",
      "No matches": "Keine Übereinstimmungen",
      "R&D Platform": "R&D-Plattform",
      "Timesheet": "Stundenzettel",
      "Input search text...": "Suchtext...",
      "No data": "Keine Daten",
      "Reset filter": "Filter zurücksetzen",
      "Select all": "Alle auswählen",
      "Filter confirm": "Filter bestätigen",
      "Filter": "Filtern",
      "Sort": "Sortieren",
      "some": "irgendwelche",
      "every": "jeder",
      "Select All Search Results": "Alle Suchergebnisse auswählen",
      "Copyright": "Urheberrecht",
      "FI Group all rights reserved": "FI-Gruppe, alle Rechte vorbehalten",
      "Terms of Use": "Allgemeine Geschäftsbedingungen",
      "Data Subject Right": "Datenverarbeitung",
      "Cookies Policy": "Cookie-Richtlinie",
      "Helpdesk": "Helpdesk",
      "Login": "Einloggen",
      "Do you want to access these applications? Ask us!": "Wollen Sie auf diese Anwendungen zugreifen? Fragen Sie uns!",
      "It seems that you dont have access to any application. Please contact your administartor": "Anscheinend haben Sie keinen Zugriff auf eine Anwendung. Bitte wenden Sie sich an Ihren Administrator",
      "Your session has expired": "Ihre Sitzung ist abgelaufen.",
      "This will redirect to Login page in": "Dadurch wird zur Anmeldeseite umgeleitet in",
      "seconds": "Sekunden",
      "Completed": "Fertig",
      "Remaining": "Verbleibend",
      "Are you sure want to delete this item?": "Möchten Sie diesen Artikel wirklich löschen?",
      "Home": "Anfang",
      "Yes": "Ja",
      "No": "Nerd",
      "elements deleted successful": "Elemente erfolgreich gelöscht",
      "Are you sure do you want to delete all items?": "Sind Sie sicher, dass Sie alle Elemente löschen möchten?",
      "Total elements": "Elemente insgesamt",
      "Total imported": "Gesamt importiert",
      "Download errors file": "Fehlerdatei herunterladen",
      "Errors": "Fehler",
      "Download warnings file": "Warndatei herunterladen",
      "Warnings": "Warnungen",
      "Deleting...": "Löschen...",
      "Deleted elements": "Gelöschte Elemente",
      "Fail deletion": "Löschen fehlgeschlagen",
      "Invert selection": "Auswahl umkehren",
      "Search": "Suche",
      "Showing only part of the results. Search to find the one you need.": "Nur ein Teil der Ergebnisse wird angezeigt. Suchen Sie, um das gewünschte Ergebnis zu finden.",
      "Not set": "Nicht gesetzt",
      "disclaimer_text": "Die über diese Anwendung bereitgestellten personenbezogenen Daten werden im Rahmen eines Dienstleistungsvertrags zwischen Ihnen und der FI GROUP verarbeitet. Sensible personenbezogene Daten sollten nicht bereitgestellt werden. Für den Fall, dass Sie sensible personenbezogene Daten zur Verfügung stellen, wird FI GROUP keine Behandlung dieser Daten vornehmen.",
      "disclaimer_profile": "Der Datenverantwortliche ist F. INICIATIVAS ESPAÑA I MAS D MAS I S.L. und alle Fragen oder Anträge zur Ausübung von Rechten können per E-Mail gestellt werden: <a mailto:'rgpd@fi-group.com'>rgpd@fi-group .com</a> oder über das Formular in Ihrem Profil > Rechte ausüben.",
      "disclaimer_specification": "Es bezieht sich auf personenbezogene Daten, die Rückschlüsse auf die rassische oder ethnische Herkunft, politische Meinungen, religiöse oder philosophische Überzeugungen oder die Gewerkschaftszugehörigkeit zulassen, sowie auf die Verarbeitung von genetischen Daten, biometrischen Daten zur eindeutigen Identifizierung einer natürlichen Person, Daten zur Gesundheit oder Daten zur sexuellen Lebensweise oder sexuellen Orientierung einer natürlichen Person ermöglichen.",
      "Go to Profile": "Gehen Sie zu Profil",
      "Language": "Sprache",
      "Change password": "Passwort ändern",
      "Hi, ": "Hallo, ",
    }
  }
}