import React, { FC, useEffect, useState } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Spin, Alert, Modal, Tag, Radio, Row, Col, Card, Tooltip } from 'antd'
import { container } from 'src/inversify.config'
import {
    TenantIdentityGroupsDataStore,
  TenantIdentitySummary
} from 'src/stores/tenant-identity/tenant-identity-store'
import { nameof } from 'src/core/utils/object'
import { formatMessage } from 'src/core/services/http.service'
import Form from 'antd/lib/form'
import TenantSelect from 'src/components/tenantEdit'
import { Query } from 'src/core/stores/data-store'
import { TableModel, TableView } from 'src/core/ui/collections/table'
import { EditOutlined, ExclamationCircleOutlined, PlusOutlined, TeamOutlined, UserOutlined } from '@ant-design/icons'
import EditMemberDialog from 'src/pages/admin/tenants/components/identity-tenant-roles-editor'
import { IdentityProps, withIdentity } from 'src/core/services/authentication'
import { RouteComponentProps, withRouter } from 'react-router'
import ContentHeader from 'src/core/ui/content-header'
import { Link } from 'react-router-dom'
import Button from 'antd/es/button'
import { CacheProps, withCache } from 'src/core/services/cache.service';
import { IdentityType } from '../../../stores/tenants/common'
import BulkUploadMenu from 'src/components/bulk-upload/bulk-upload-menu'
const { info, error, confirm } = Modal;


export const AddTenantDialog: FC<{ onClose: (selectedItem: any, cancel: boolean) => void; isAdmin: boolean } & WithTranslation> = ({ t, onClose, isAdmin }) => {

    const [selectTenant, setSelectedTenant] = useState()
    useEffect(() => {
    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    const OnChange = (value: any) => {
        setSelectedTenant(value ? value.value == "00000000-0000-0000-0000-000000000000" ? undefined : value.value : undefined)
    }

    return (
        <Modal maskClosable={false} width={600} title={t('Add Tenant Roles to Group')} visible={true} onOk={() => onClose(selectTenant, true)} onCancel={() => onClose(undefined, false)}>

            <Row>

                <label> {t("Tenant")} </label>
                <TenantSelect addDefault={isAdmin} onChange={(value) => OnChange(value)} />
            </Row>



        </Modal>
    )
}



interface TenantIdentityListProps extends WithTranslation, CacheProps,  IdentityProps, RouteComponentProps { isAdmin: boolean }
const TenantIdentityList: FC<TenantIdentityListProps> = (props) => {
    const { t } = props
    const entityIdentityDataStore = container.get<TenantIdentityGroupsDataStore>(TenantIdentityGroupsDataStore)
    const entityIdentityDataState = entityIdentityDataStore.state


    const [query, setQuery] = useState({
        searchQuery: '',
        orderBy: [{ field: 'name', direction: 'Ascending', useProfile: false }],
        skip: 0,
        take: 10,
    } as Query)

    const [editIdentityVisible, setEditIdentityVisible] = useState(false)
    const [addTenantVisible, SetAddTenantVisible] = useState(false)
    const [selectedIdentity, setSelectedIdentity] = useState<TenantIdentitySummary>()
    const [selectedTenant, setSelectedTenant] = useState<any>(undefined)

    useEffect(() => {
        var queryStored = props.cache.getWithCustomKey("entityGroupList")
        if (queryStored)
            setQuery(queryStored);
          entityIdentityDataStore.load(queryStored || query)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    const onCloseAddTenant = (selectedTenantId: any, close: boolean) => {
        SetAddTenantVisible(false)
        if (close) {
            setSelectedTenant(selectedTenantId)
            setEditIdentityVisible(true)
        }
  }
  const handleClose = async (identityId: string, tenantId: string) => {
    await entityIdentityDataStore.Delete({ tenantId: tenantId, identityId: identityId })
    entityIdentityDataStore.load(query)
  };

    const tableModel = {
        query: query,
        columns: [

            {
                sortable: true,
                searchable: true,
                useProfile: false,
                width: 250,
                maxWidth: 250,
                field: 'name',
                title: t('Group Name'),
                renderer: (data: TenantIdentitySummary) => (
                    <span>
                        <Link to={{ pathname: `/admin/permissions/${data.identityId}`, state: { groupId: data.identityId, groupName: data.displayName } }}>
                            {data.identityType === IdentityType.Group ? <TeamOutlined /> : <UserOutlined />}&nbsp;<span>{data.displayName}</span>
                        </Link>
                    </span>

                ),
            },
            {

                field: 'entities',
                title: t('Tenants'),
                renderer: (data: TenantIdentitySummary) => <span>
                    {data.tenants.any(e => e.id == undefined) &&
                        <Tag
                            onClick={() => {
                                setSelectedIdentity(data)
                                setSelectedTenant(undefined)
                                setEditIdentityVisible(true)

                      }}
                      closable
                      onClose={x => {
                        x.preventDefault()
                        confirm({
                          title: t('Are you sure to revoke this access?'),
                          icon: <ExclamationCircleOutlined />,
                          content: "",
                          okText: t('Yes'),
                          okType: 'danger',
                          okButtonProps: {
                            disabled: false,
                          },
                          cancelText: t('No'),
                          onOk() {
                            handleClose(data.identityId, undefined)
                          },
                          onCancel() {

                          },
                        })

                      }}
                            style={{ fontSize: "12px", padding: "2px 15px", cursor: "pointer" }} color={data.tenants.any(e => e.id == null) ? "#0000a4" : "seagreen"}>
                            {t("Global")} </Tag>}
                  {data.tenants.filter(e => e.id != undefined).map(e =>

                        <Tag
                            onClick={() => {
                                setSelectedIdentity(data)
                                setSelectedTenant(e.id)
                                setEditIdentityVisible(true)

                      }}
                      closable
                      onClose={x => {
                        x.preventDefault()
                        confirm({
                          title: t('Are you sure to revoke this access?'),
                          icon: <ExclamationCircleOutlined />,
                          content: "",
                          okText: t('Yes'),
                          okType: 'danger',
                          okButtonProps: {
                            disabled: false,
                          },
                          cancelText: t('No'),
                          onOk() {
                            handleClose(data.identityId, e.id)
                          },
                          onCancel() {

                          },
                        })

                      }}
                            style={{ fontSize: "12px", padding: "2px 15px", cursor: "pointer" }} color={e.id ?  "seagreen"  : "#0000a4"}>
                            {e.id ? !e.title || e.title.length === 0 ? t("(Empty Name)") : e.title : t("Global")}
                        </Tag>
                    )}
                    <Tooltip placement="topRight" title={t('Add Tenant Roles')}>
                        <Button
                            onClick={() => {
                                setSelectedIdentity(data)
                                SetAddTenantVisible(true)

                            }}

                            type="primary" icon={<PlusOutlined />} />
                    </Tooltip>

                </span>
            },



        ],
        data: entityIdentityDataState.value,

    } as TableModel<TenantIdentitySummary>

    const bulkUploadOptions = [
        {
            optionLabel: "Bulk Insert Groups's Roles",
            modalName: "Bulk Insert Groups's Roles",
            bulkInsertTemplateName: "Template import groups's roles",
            bulkInsertTemplateUrl: `api/v1/admin/tenantidentities/template/groups`,
            bulkInsertUrl: `api/v1/admin/tenantidentities/bulk_insert/groups`,
        }]

    return (

        <div style={{ width: '100%' }}>
            <TableView
                small
                rowKey="identityId"
                onQueryChanged={(query: Query) => {
                    props.cache.saveWithCustomKey("entityGroupList", query)
                    setQuery(query)
                    entityIdentityDataStore.load(query)
                }}
                leftToolbar={
                    <Row gutter={5}>
                        <Col>
                            <BulkUploadMenu options={bulkUploadOptions} />
                        </Col>
                    </Row>
                }
                onRefresh={() => entityIdentityDataStore.load(query)}
                model={tableModel}
                error={entityIdentityDataState.errorMessage.value && formatMessage(entityIdentityDataState.errorMessage.value)}
            />

            {editIdentityVisible && (
                <EditMemberDialog
                    identityId={(selectedIdentity as TenantIdentitySummary).identityId}
                    entityId={selectedTenant}
                    isGroup
                    onClose={() => {
                        setEditIdentityVisible(false)
                        setSelectedIdentity(undefined)
                        entityIdentityDataStore.load(query)
                    }}
                    {...props}
                />
            )}

            {addTenantVisible && (
                <AddTenantDialog
                    onClose={onCloseAddTenant}
                    {...props}
                />
            )}

        </div>
    )
}
export default  withCache (withIdentity(withTranslation()(withRouter(TenantIdentityList))))

