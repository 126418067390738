import * as React from 'react';
import { withIdentity, IdentityProps } from "../core/services/authentication";
import { Avatar, Menu, Select } from 'antd';
import { withTranslation, WithTranslation } from 'react-i18next'
import { container } from 'src/inversify.config';
import { PreferencesService } from 'src/core/services/preferences.service';
import moment from 'moment';
import i18next from 'i18next';
import HttpService from "../core/services/http.service";


const MenuItem = Menu.Item;

interface LanguageBoxProps extends WithTranslation, IdentityProps {
  isMobileOrTablet?: boolean
  availableLanguages?: string[]
  showFlag?: boolean
}

interface LanguageBoxState {
  language: string
  languages: { key: string, value: string, flag: any }[]
}

class LanguageBox extends React.Component<LanguageBoxProps, LanguageBoxState> {
  private readonly preferencesService: PreferencesService;

  constructor(props: LanguageBoxProps) {
    super(props);
    const languages = [] as any[]
    if (props.availableLanguages == null || props.availableLanguages.length == 0 || props.availableLanguages.filter(o => o == 'en').length)
      languages.push({ key: 'en', value: 'English' })
    if (props.availableLanguages == null || props.availableLanguages.length == 0 || props.availableLanguages.filter(o => o == 'es').length)
      languages.push({ key: 'es', value: 'Spanish' })
    if (props.availableLanguages == null || props.availableLanguages.length == 0 || props.availableLanguages.filter(o => o == 'fr').length)
      languages.push({ key: 'fr', value: 'French' })
    if (props.availableLanguages == null || props.availableLanguages.length == 0 || props.availableLanguages.filter(o => o == 'it').length)
      languages.push({ key: 'it', value: 'Italian' })
    if (props.availableLanguages == null || props.availableLanguages.length == 0 || props.availableLanguages.filter(o => o == 'pt').length)
      languages.push({ key: 'pt', value: 'Portuguese (PT)' })
    if (props.availableLanguages == null || props.availableLanguages.length == 0 || props.availableLanguages.filter(o => o == 'pt-br').length)
      languages.push({ key: 'pt-br', value: 'Portuguese (BR)' })

    this.state = {
      language: HttpService.language,
      languages: languages
    }
    this.onChangeLanguage = this.onChangeLanguage.bind(this);
    this.getBrowserLanguage = this.getBrowserLanguage.bind(this);
    this.configureLanguage = this.configureLanguage.bind(this);
    this.preferencesService = container.get(PreferencesService);
  }

  UNSAFE_componentWillMount() {
    if (this.preferencesService) {
      this.preferencesService.current().then(prefs => {
        if (prefs && prefs.currentLanguage) {
          this.configureLanguage(prefs.currentLanguage);
        } else {
          this.configureLanguage(this.getBrowserLanguage());
        }
      }).catch(error => {
        this.configureLanguage(this.getBrowserLanguage());
      });
    }
  }

  private getBrowserLanguage() {
    var supportedLangs = this.state.languages.map(o => o.key);
    var language: string = window.navigator.language || (window.navigator as any).userLanguage || supportedLangs[0];
    supportedLangs.forEach(lang => {
      if (language && language == lang || language.startsWith(lang))
        language = lang;
    });
    return language || supportedLangs[0];
  }

  private configureLanguage(language: string) {
    moment.locale(language);
    i18next.changeLanguage(language);
    this.setState({ language: language });
    HttpService.language = language;
  }

  private onChangeLanguage(e: any) {
    this.preferencesService.setCurrentLanguage(e.target.value);
    this.configureLanguage(e.target.value);
    HttpService.language = e.target.value;
  }

  public render() {
    const { t } = this.props;

    if (!this.state.language)
      return <div></div>;

    var currentLanguages = this.state.languages.filter(o => o.key == this.state.language);
    if (currentLanguages.length == 0)
      currentLanguages = this.state.languages
    var currentLanguage = currentLanguages[0];
    return <select onChange={(e) => this.onChangeLanguage(e)} placeholder={t("Select Language")} className="selector" defaultValue={this.state.language}>

      {this.state.languages.map(lang => <option className="option-selector" value={lang.key} key={lang.key}>
        {t(lang.value)}
      </option>
      )}

    </select>
  }
}
export default withTranslation()(withIdentity(LanguageBox));