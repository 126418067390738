import Form from 'antd/lib/form'
import React, { FC, useEffect, useState } from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { CommandResult } from 'src/core/stores/types'
import { container } from 'src/inversify.config'
import { Alert, Spin, Radio, Input, AutoComplete, Modal } from 'antd'
import { nameof } from 'src/core/utils/object'
import { TenantItemStore, CreateTenant } from 'src/stores/tenants/tenants-store'
import EntitySelect from 'src/components/tenantEdit'
import HttpService from 'src/core/services/http.service'
import { Query } from 'src/core/stores/data-store'
import AreaEditor from 'src/components/areaEdit'

interface NewEntityFormData {
  title: string
  areaId: any
  parent: { value: string; label: string }
  taxNumber: string
  viesNumber: string
}

const NewEntityDialog: FC<{ onClose: (response?: CommandResult<CreateTenant>) => void } & WithTranslation> = (props) => {
  const { t, onClose } = props
  const [form] = Form.useForm<NewEntityFormData>()

  const newEntityStore = container.get(TenantItemStore)
  const newEntityState = newEntityStore.state
  const [options, setOptions] = useState([])

  const [confirmLoading, setConfirmLoading] = useState(false)

  const onNewItem = async () => {
    setConfirmLoading(true)
    let item: NewEntityFormData
    try {
      item = (await form.validateFields()) as NewEntityFormData
    } catch {
      setConfirmLoading(false)
      return
    }

    const postData: CreateTenant = {
      title: item.title,
      areaId: item.areaId.id,
      parentId: item.parent?.value,
      taxNumber: item.taxNumber,
      viesNumber: item.viesNumber
    }
    let response = await newEntityStore.create(postData)
    if (response) {
      form.resetFields()
      onClose({ ...response, title: postData.title })
    }
    setConfirmLoading(false)
  }


  return (
    <Modal
      width={600}
      maskClosable={false}
      title={t('New Entity')}
      confirmLoading={confirmLoading}
      visible={true}
      onOk={onNewItem}
      onCancel={() => onClose()}
      cancelText={t('Cancel')}
      okText={t('Ok')}
    >
      {newEntityState.errorMessage.get() && <Alert type="error" message={t(newEntityState.errorMessage.value || '')} />}
      <Spin spinning={newEntityState.isBusy.get()}>
        <Form size="middle" form={form} layout="vertical" autoComplete="off" initialValues={{}}>

          <Form.Item name={nameof<NewEntityFormData>('title')} label={t('Title')} rules={[{ required: true, message: t('Please enter a title') }]}>
            <Input autoComplete="off" />
          </Form.Item>
          <Form.Item name={nameof<NewEntityFormData>('areaId')} label={t('Area')} rules={[{ required: true, message: t('Area is required') }]}>
            <AreaEditor valueAsItemReference />
          </Form.Item>


          <Form.Item
            label={t('Parent')}
            name={nameof<NewEntityFormData>('parent')}
          >
            <EntitySelect />
          </Form.Item>

          <Form.Item name={nameof<NewEntityFormData>('taxNumber')} label={t('CIF')} >
            <Input autoComplete="off" />
          </Form.Item>
          <Form.Item name={nameof<NewEntityFormData>('viesNumber')} label={t('European VAT')}>
            <Input autoComplete="off" />
          </Form.Item>

        </Form>
      </Spin>
    </Modal>
  )
}

export default withTranslation()(NewEntityDialog)
