import { FC, useState, useMemo, useEffect } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Avatar, Card, Modal, Select, Tag, Tooltip, Button, Row, Col } from 'antd'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { TableModel, TableView } from 'src/core/ui/collections/table'
import { clone, formatDateTime } from 'src/core/utils/object'
import ContentTitle from 'src/core/ui/content-header'
import { container } from 'src/inversify.config'
import { formatMessage } from 'src/core/services/http.service'
import { Query } from 'src/core/stores/data-store'
import { AuthenticationProvider, AuthLogsStore, AuthLogsSummary, LoginResult } from 'src/stores/authLogs/logs-store'
import { CacheProps, withCache } from 'src/core/services/cache.service'
import { CheckOutlined, CloseOutlined, LaptopOutlined, LockOutlined, ReloadOutlined, UnlockOutlined, LoadingOutlined,DownloadOutlined } from '@ant-design/icons'
import MicrosoftIcon from 'src/core/assets/images/login/microsoft-icon.png'
import HttpService from 'src/core/services/http.service'
import FileSaver from 'file-saver'
import { buildUrl } from 'src/components/util'

import { EditOutlined, ExclamationCircleOutlined, PlusOutlined, TeamOutlined, UserOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'

import BulkUploadMenu from 'src/components/bulk-upload/bulk-upload-menu'
const { info, error, confirm } = Modal;

const { Option } = Select

interface AuthLogsViewProps extends RouteComponentProps, CacheProps, WithTranslation {

}

const AuthLogs: FC<AuthLogsViewProps> = (props) => {
  const { t } = props
  const authLogsStore = container.get<AuthLogsStore>(AuthLogsStore)
  const authLogsStoreState = authLogsStore.state
  const [isBusy, setIsBusy] = useState(false)
  const [query, setQuery] = useState({
    searchQuery: '',
    orderBy: [{ field: 'date', direction: 'Descending', useProfile: false }],
    skip: 0,
    take: 10,
    parameters: {
      includeHierarchy: 'true',
    },
    odataObject: {
      filter: {}
    },
  } as Query)

  const [resultFilter, setResultFilter] = useState<any>()
  const [providerFilter, setProviderFilter] = useState<any>()
  const [initPagination, setInitPagination] = useState(false)
  const httpService = container.get(HttpService)


    useEffect(() => {
        var queryStored = props.cache.getWithCustomKey(authLogsStore.baseUrl)
        if (queryStored && queryStored.query)
            setQuery(queryStored.query)
        if (queryStored && queryStored.result)
            setResultFilter(queryStored.result)
        if (queryStored && queryStored.provider)
            setProviderFilter(queryStored.provider)

        authLogsStore.load(Build(queryStored?.query || query, queryStored?.result || resultFilter, (queryStored?.provider) || providerFilter))
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const onDownloadExcel = async () => {
    const downloadExcelUrl: string = 'api/v1/admin/authenticationlogs/export'
    const downloadExcelFilename: string = 'AuthLogs.xlsx'
    setIsBusy(true)
    const result = await httpService.get(`${downloadExcelUrl}?${buildUrl(query)}`, {
      responseType: 'arraybuffer'
    })
    const blob = new Blob([result.data as any], { type: result.headers['content-type'] });
    (FileSaver as any).saveAs(blob, downloadExcelFilename)
    setIsBusy(false)
  }

  const GetLoginResult = (data) => {
    if (data.result == "Success")
      return <Tag icon={<CheckOutlined />} color="#87d068">
        {t("Success")}
      </Tag>
    if (data.result == "Failure")
      return <Tag icon={<CloseOutlined />} color="red">
        {t("Failure")}
      </Tag>

    if (data.result == "UserLocked")
      return <Tag icon={<LockOutlined />} color="yellow">
        {t("UserLocked")}
      </Tag>

    if (data.result == "Unlocked")
      return <Tag icon={<LockOutlined />} color="green">
        {t("User Unlocked")}
      </Tag>

    if (data.result == "FullUserLocked")
      return <Tag icon={<LockOutlined />} color="#f50">
        {t("Permanent lock")}
      </Tag>

    return null

  }

  const load = (q: Query) => {
    authLogsStore.load(q)
  }

  const GetProvider = (data) => {
    if (data.provider == "Microsoft")
      return <span><Avatar style={{ verticalAlign: 'middle', fontSize: 18, marginRight: 10 }} shape="square" src={MicrosoftIcon} />{t("Microsoft")}</span>

    return <span><LaptopOutlined style={{ verticalAlign: 'middle', fontSize: 22, marginRight: 10 }} />{t("Local")}</span>
  }

  const tableModel = {
    query: query,
    columns: [
      {
        sortable: true,
        centered: true,
        field: 'userName',
        title: t('Username'),
        renderer: (data) => <span>{data.userName}</span>
      },
      {
        field: 'reason',
        title: t('Reason'),
        renderer: (data) => <span>{t(data.reason)}</span>,
      },
      {
        sortable: true,
        field: 'date',
        title: t('Date'),
        renderer: (data) => <span>{formatDateTime(data.date)}</span>,
      },
      {
        centered: true,
        field: 'result',
        title: t('Login Result'),
        renderer: (data) => <>{GetLoginResult(data)}</>
      },
      {
        field: 'authenticationProvider',
        title: t('Authentication Provider'),
        renderer: (data) => <>{GetProvider(data)}</>
        ,
      },
      {
        field: 'returnUrl',
        title: t('Return Url'),
        renderer: (data) => <span>{data.returnUrl}</span>
        ,
      }
    ],
    data: authLogsStoreState.value,
    sortFields: [
      { field: 'username', text: t('Username'), useProfile: false },
      { field: 'date', text: t('Date'), useProfile: false }
    ],
  } as TableModel<AuthLogsSummary>

  const Build = (query: Query, result: any, provider: any): Query => {

    if (result) {
      query.parameters.result = result
    }
    else {
      query.parameters.result = undefined
    }

    if (provider) {
      query.parameters.provider = provider
    }
    else {
      query.parameters.provider = undefined
    }

      props.cache.saveWithCustomKey(authLogsStore.baseUrl,{ query: query, result: result, provider: provider })
    return query
  }

  const onResultFilterChange = (result: any) => {
    setInitPagination(!initPagination)
    setResultFilter(result)
    var newquery = query
    newquery.skip = 0
    setQuery(newquery)
    authLogsStore.load(Build(newquery, result, providerFilter))
  }

  const onProviderFilterChange = (provider: any) => {
    setInitPagination(!initPagination)
    setProviderFilter(provider)
    var newquery = query
    newquery.skip = 0
    setQuery(newquery)
    authLogsStore.load(Build(newquery, resultFilter, provider))
  }

  const rightToolbar = (
    <>
      <li>
        <Select defaultValue={resultFilter}
          allowClear placeholder={t("Select Login Result...")}
          style={{ width: 250 }}
          onChange={(value) => onResultFilterChange(value)}
        >
          <Option value={LoginResult.Success.toString()}>{
            <Tag icon={<CheckOutlined />} color="#87d068">
              {t("Success")}
            </Tag>}
          </Option>

          <Option value={LoginResult.Failure.toString()}>{
            <Tag icon={<CloseOutlined />} color="red">
              {t("Failure")}
            </Tag>}
          </Option>

          <Option value={LoginResult.UserLocked.toString()}>{
            <Tag icon={<LockOutlined />} color="yellow">
              {t("UserLocked")}
            </Tag>}
          </Option>

          <Option value={LoginResult.Unlocked.toString()}>{
            <Tag icon={<UnlockOutlined />} color="green">
              {t("User Unlocked")}
            </Tag>}
          </Option>

          <Option value={LoginResult.FullUserLocked.toString()}>{
            <Tag icon={<LockOutlined />} color="#f50">
              {t("Permanent lock")}
            </Tag>}
          </Option>
        </Select>
      </li>

      <li>
        <Select defaultValue={providerFilter}
          allowClear placeholder={t("Select Authentication Provider...")}
          style={{ width: 250 }}
          onChange={(value) => onProviderFilterChange(value)}
        >
          <Option value={AuthenticationProvider.Local.toString()}>
            <span><LaptopOutlined style={{ verticalAlign: 'middle', fontSize: 22, marginRight: 10 }} />{t("Local")}</span>
          </Option>

          <Option value={AuthenticationProvider.Microsoft.toString()}>
            <span><Avatar style={{ verticalAlign: 'middle', fontSize: 18, marginRight: 10 }} shape="square" src={MicrosoftIcon} />{t("Microsoft")}</span>
          </Option>

        </Select>
      </li>

      <li>
        <Tooltip placement="topLeft" title={t('Export to Excel')}>
          <Button onClick={e => onDownloadExcel()} disabled={isBusy}>
            {isBusy && <LoadingOutlined spin />}
            {!isBusy && <DownloadOutlined />}
          </Button>
        </Tooltip>
      </li>

    </>
  )

  return (

<div style={{ width: '100%' }}>
      <TableView
        rightToolbar={rightToolbar}
        model={tableModel}
        onQueryChanged={(query: Query) => {
            setQuery(query)
            authLogsStore.load(Build(query, resultFilter, providerFilter))
        }}
        onRefresh={() => authLogsStore.load(query)}
        error={authLogsStoreState.errorMessage.value && formatMessage(authLogsStoreState.errorMessage.value)}
        >
     </TableView>
    </div>
  )
}

// Wire up the React component to the Redux store
export default withCache(withTranslation()(withRouter(AuthLogs)))

