import * as React from 'react'
import { Form, Spin, Input, Modal, Row, Col, Alert, Checkbox } from 'antd'
let FormItem = Form.Item
import autobind from 'autobind-decorator'
import { RoleScopeItemStore, NewRoleScopeItem } from 'src/stores/roles/rolescopes-store'
import { withTranslation, WithTranslation } from 'react-i18next'
import { nameof } from 'src/core/utils/object'
import { container } from '../../../../../inversify.config'

interface NewRoleScopeViewProps {
  id: string,
  applicationId: string,
  onClose: () => void
}


const NewRoleScopeView: React.FC<NewRoleScopeViewProps & WithTranslation> = (props) => {
  const [form] = Form.useForm<NewRoleScopeItem>()
  const [initialValues, setInitialValues] = React.useState<NewRoleScopeItem | undefined>({ scope: "" })

  const currentStore = React.useMemo(() => container.get(RoleScopeItemStore), [])
  const currentState = currentStore.state



  const onCancel = () => {
    props.onClose()
  }

  const onCreate = async () => {
    let item: NewRoleScopeItem
    try {
      item = (await form.validateFields()) as NewRoleScopeItem
    } catch {
      return
    }
    currentStore.changeUrl(props.applicationId,props.id)
    let response = await currentStore.create(item)
    if (response) {
      form.resetFields()
      props.onClose()
    }
  }

  const { t } = props


  return (
    <Modal
      visible
      onCancel={() => onCancel()}
      onOk={() => onCreate()}
      closable={false}
      title={t("New role's scope")}
      cancelText={t('Cancel')}
      okText={t('Ok')}
    >
      {currentState.errorMessage.get() && <Alert type="error" message={t(currentState.errorMessage.value || '')} />}
      <Spin spinning={currentState.isBusy.get()}>
        <Form id="modaForm" form={form} initialValues={initialValues}>
          <FormItem label={t("Scope") }
            name={nameof<NewRoleScopeItem>('scope')}
            rules={[{ required: true, message: t('Please enter the scope!') }]}
          >
            <Input placeholder={t("Scope")} />
          </FormItem>
          <small>Use lowercase letters and numbers. Only allowed symbols: . / : _ -</small>
        </Form>
      </Spin>
    </Modal>
  )
}

// Wire up the React component to the Redux store
export default withTranslation()(NewRoleScopeView)
