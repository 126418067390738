import * as React from 'react'
import { Input, Card, Button, Spin, notification, Modal, Row, Col, Checkbox } from 'antd'
let FormItem = Form.Item
import { UserProfileItem } from 'src/stores/profiles/profile-store'
import { nameof } from 'src/core/utils/object'
import { FC, MouseEventHandler } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import Form from 'antd/lib/form'
import LangEditor from 'src/components/langEdit'
import HttpService from 'src/core/services/http.service'
import FileSaver from 'file-saver'
import { RouteComponentProps } from 'react-router'
import { Link } from 'react-router-dom'
import { IdentityProps, withIdentity } from 'src/core/services/authentication'
import { container } from '../../inversify.config'
import { BankOutlined, CheckOutlined, DownloadOutlined } from '@ant-design/icons'

interface ProfilePersonalInfoViewProps extends RouteComponentProps, WithTranslation, IdentityProps {
  profile: UserProfileItem,
  onUpdateItem: (values: any) => Promise<any>,
}

interface ProfilePersonalInfoViewState {
  isBusy: boolean
}

const ProfilePersonalInfoView: FC<ProfilePersonalInfoViewProps> = (props) => {
  const { t } = props
  const [isBusy, setIsBusy] = React.useState(false)
  const [form] = Form.useForm<UserProfileItem>()
  const httpService = container.get(HttpService)

  const [changePasswordRequest, setChangePasswordRequest] = React.useState<boolean>(false)


  const downloadMyData = async () => {

    const myDataResource: string = 'api/v1/profile/myData'
    setIsBusy(true)
    const result = await httpService.get(myDataResource, {
      responseType: 'arraybuffer'
    })
    const lang = (props.profile as any)['language']
    const fileName = props.t("My data", { lng: lang })
    const blob = new Blob([result.data as any], { type: result.headers['content-type'] });
    (FileSaver as any).saveAs(blob, `${fileName}.xlsx`)
    setIsBusy(false)
  }
  const onUpdate = async () => {
    let item: UserProfileItem
    try {
      item = (await form.validateFields()) as UserProfileItem
      await props.onUpdateItem(item)
      notification['success']({
        message: t("Changes have been saved"),
        icon: <CheckOutlined style={{ color: '#108ee9' }} />,
      })
    } catch {
      return
    }

  }
  const CanViewClients = () => {
    return props.identity.roles && props.identity.roles.some(role => role === "Administrator")
  }

  const onChangePassword = async () => {
    setChangePasswordRequest(true)
    try {
      var httpService = container.get(HttpService)
      await httpService.get<string>(`${props.identity.identityUrl}/api/v1/profile/resetPassword/` + props.identity.name)
      Modal.info({
        title: t('Change password request Success'),
        content: (
          <div>
            <p>{t('Please check your email inbox. We have sent instructions on how reset your password.')}</p>
          </div>
        ),
        onOk() { }
      })
    }
    catch {
      Modal.error({
        title: t('Change password request fail'),
        content: (
          <div>
            <p>{t('Please contact to an Administrator')}</p>
          </div>
        ),
        onOk() { }
      })
    }

    setChangePasswordRequest(false)
  }


  return <Spin spinning={isBusy}>
    <Card title={<div>
      <p>{t("Your information")}</p>
      <Button icon={<DownloadOutlined />} onClick={downloadMyData}>{t('My data')}</Button>
    </div>}>
      <Form form={form} initialValues={props.profile} className='profile-form'>
        <FormItem name={"userName"} label={t("Username")}>
          <Input readOnly={true} />
        </FormItem>
        <FormItem name={"email"} label={t("Email")}>
          <Input readOnly={true} />
        </FormItem>
        <FormItem label={t("First name")} name={nameof<UserProfileItem>('firstName')}
          rules={[{ required: true, message: t('Please input your first name!') }]}

        >
          <Input placeholder={t("First name")} />
        </FormItem>
        <FormItem label={t("Last name")} name={nameof<UserProfileItem>('lastName')}
          rules={[{ required: true, message: t('Please input your last name!') }]}
        >
          <Input placeholder={t("Last name")} />
        </FormItem>
        <FormItem label={t("Preferred language")} name={nameof<UserProfileItem>('language')}
          rules={[{ required: true, message: t('Please select your preferred language!') }]}
        >
          <LangEditor placeholder={t("Preferred language")} />
        </FormItem>
        <Button type="primary" loading={changePasswordRequest} onClick={() => onChangePassword()} style={{ float: 'left', marginTop: '6px' }}>{t('Change password')}</Button>
        <div
          style={{
            textAlign: 'right',
            marginTop: 10
          }}
        >
          <Button type="primary" onClick={() => { form.resetFields() }} style={{ marginRight: 8 }}>
            {t('Cancel')}
          </Button>
          <Button type="primary" htmlType="submit" onClick={() => onUpdate()} loading={isBusy} >
            {t('Save')}
          </Button>
        </div>

      </Form>

    </Card>
  </Spin>
}

export default withIdentity((withTranslation()(ProfilePersonalInfoView)))