import autobind from 'autobind-decorator'
import * as React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Input, Alert, Card } from 'antd'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { AreasStore, AreaItem } from 'src/stores/areas/areas-store'
import { ItemState, TableModel, TableView } from 'src/core/ui/collections/table'
import { formatDate, formatBoolean, clone } from 'src/core/utils/object'

import ContentTitle from 'src/core/ui/content-header'
import Link from 'src/core/ui/link'
import { CommandResult } from '../../../core/stores/types'
import { withIdentity, IdentityProps } from "src/core/services/authentication"
import { FC, useState } from 'react'
import { container } from '../../../inversify.config'
import { DesktopOutlined, ExceptionOutlined, CheckOutlined, GoldOutlined } from '@ant-design/icons'
import { formatMessage } from '../../../core/services/http.service'
import { Query } from '../../../core/stores/data-store'
import { CacheProps, withCache } from '../../../core/services/cache.service'

// tslint:disable-next-line:no-empty-interface
interface AreaViewProps extends RouteComponentProps, WithTranslation, CacheProps {
}


const AreaList: FC<AreaViewProps & IdentityProps> = (props) => {
  const { t } = props
  const currentStore = React.useMemo(() => container.get(AreasStore), []) as AreasStore
  const currentState = currentStore.state
  const [query, setQuery] = useState({ searchQuery: '', orderBy: [{ field: 'name', direction: 'Ascending', useProfile: false }], skip: 0, take: 10} as Query)
  const [selectedItem, setSelectedItem] = useState<AreaItem | undefined>()
  const cacheKey = "IDENTITY-AREAS-LIST"


  React.useEffect(() => {
    var currentQuery = query
    var queryStored = props.cache.getWithCustomKey(cacheKey, true)
    if (queryStored && queryStored.query)
      currentQuery = queryStored.query
    setQuery(currentQuery)
    load(currentQuery)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const load = (query: Query) => {
    props.cache.saveWithCustomKey(cacheKey, { query: query })
    currentStore.load(query)
  }

  const onQueryChanged = (query: Query) => {
    setQuery(query)
  }


  const onDeleteRow = async (item: AreaItem) => {
    await currentStore.delete(item.id)
    load(query)
  }



  const tableModel = {
    query: query,
    columns: [
      {
        sortable: true,
        field: 'name',
        title: t('Area'),
        renderer: (data) => <span> <GoldOutlined />&nbsp;<span>{data.name}</span></span>
      },
      {
        sortable: false,
        field: 'default',
        title: t('Is Default Area'),
        renderer: (data) => <span> {data.default ? <CheckOutlined /> : ""} </span>
      },
      {
        sortable: false,
        field: 'system',
        title: t('Is System Area'),
        renderer: (data) => <span> {data.system ? <CheckOutlined /> : ""} </span>
      }
    ],
    data: currentState.value,
    sortFields: [
    ],
  } as TableModel<AreaItem>
  return (
    <div>
      <ContentTitle title={t("Areas")} />
      <Card>

        <TableView
          title={""}
          model={tableModel}
          onQueryChanged={(q: Query) => {setQuery(q) ; load(q)}}
          onRefresh={() => load(clone(query))}
          canEdit={false}
          canDelete={false}
          error={currentState.errorMessage.value && formatMessage(currentState.errorMessage.value)}
          canCreateNew={false}>
        </TableView>
      </Card>
    </div>
  )
}

export default withCache(withTranslation()(withRouter(withIdentity(AreaList))))