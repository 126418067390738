import React, { Component, FC, useEffect, useRef, useState } from 'react'
import { Route, withRouter, Switch, RouteComponentProps } from 'react-router'
import { ConfigurationService, AppConfiguration } from 'src/core/services/configuration.service'
import SecureContent, { IdentityProps, withIdentity } from 'src/core/services/authentication'
import { Layout, Spin } from 'antd'
import Home from './home'
import Users from './users/user-list'
import UserDetail from './users/user-detail'
import RoleDetail from './applications/roles/role-detail'
import Applications from './applications/application-list'
import Policy from './policies/policy-list'
import Config from './configSettings/config-list'
import Areas from './areas/area-list'
import ApplicationDetail from './applications/application-detail'
import Groups from './groups/group-list'
import GroupDetail from './groups/group-detail'
import Shell from 'src/core/ui/shell'
import PatList from './pats/pat-list'
import PatControl from './pats-control'
import PermissionInspector from './inspector/permission-list'
import { container } from '../../inversify.config'
import logo from 'src/core/assets/logo.png'
import footerLogo from 'src/core/assets/footer-logo.png'
import TenantsView from './tenants/tenant-list'
import TenantDetail from './tenants/tenant-details'
import TenantIdentityView from './identity-tenants'
import IdentityGroupDetail from './identity-tenants/tenant-group-detail'
import ShellNotification from 'src/components/systemNotification/shell-notification'
import ShellIdleApp from 'src/core/ui/shell-idle-app'
import ShellDisclaimer from '../../core/ui/shell-disclaimer'
import LogsView from './Logs/'
import TwoModeAuthView from './twoModeAurhentification/tenant-list'
import SiderView from 'src/core/ui/sider-menu'
import { ItemType } from 'antd/lib/menu/hooks/useItems'
import { ApartmentOutlined, CrownOutlined, DesktopOutlined, ExceptionOutlined, FileSearchOutlined, GoldOutlined, HomeOutlined, KeyOutlined, LaptopOutlined, LoadingOutlined, SecurityScanOutlined, UsergroupAddOutlined, UserOutlined } from '@ant-design/icons'
import { withTranslation, WithTranslation } from 'react-i18next'

interface AdminRoutesProps extends RouteComponentProps, IdentityProps, WithTranslation {
}



const AdminRoutes: FC<AdminRoutesProps> = (props) => {
  const configurationService = container.get(ConfigurationService)
  const [config, setConfig] = useState<AppConfiguration>()
  const inputRef = useRef()
  const [active, setActive] = useState<string>()
  const [authenticate, setAuthenticate] = useState(false)


  useEffect(
    () => {
      //if (!isGod && !isAdmin) {
      //  window.location.href = props.identity.identityUrl;
          //}
      configurationService.current().then(x => { setConfig(x) })

    }, [inputRef]
  )



  const getMenu = () => {

    let menu = []

    menu.push({
      key: '0',
      icon: <HomeOutlined />,
      label: 'Home',
      target: "/admin",
      exact: true
    })
    menu.push({
      key: '1',
      icon: <UserOutlined />,
      label: 'Users',
      requiredRoles: ["Administrator", "AreaAdministrator"],
      target: "/admin/users"
    })
    menu.push({
      key: '2',
      icon: <UsergroupAddOutlined />,
      label: 'Groups',
      requiredRoles: ["Administrator", "AreaAdministrator"],
      target: "/admin/groups"
    })
    menu.push({
      key: '3',
      icon: <DesktopOutlined />,
      label: 'Applications',
      requiredRoles: ["Administrator"],
      target: "/admin/applications"
    })

    menu.push({
      key: '4',
      icon: <FileSearchOutlined />,
      label: 'Logs',
      requiredRoles: ["Administrator"],
      target: "/admin/logs"
    })
    //menu.push({
    //  key: '5',
    //  icon: <ExceptionOutlined />,
    //  label: props.t('Policies'),
    //  requiredRoles: ["Administrator"],
    //  target: "/admin/policies"
    //})

    menu.push({
      key: '6',
      icon: <GoldOutlined />,
      label: 'Areas',
      requiredRoles: ["Administrator"],
      target: "/admin/areas"
    })


    menu.push({
      key: '7',
      icon: <ApartmentOutlined />,
      label: 'Tenants',
      requiredRoles: ["Administrator"],
      target: "/admin/tenants"
    })

    menu.push({
      key: '8',
      icon: <SecurityScanOutlined />,
      label: 'Permissions',
      requiredRoles: ["Administrator"],
      target: "/admin/permissions"

    })
    menu.push({
      key: '9',
      icon: <KeyOutlined />,
      label: 'Personal Access Tokens',
      requiredRoles: ["Administrator"],
      target: "/admin/pats"
    })
    menu.push({
      key: '10',
      icon: <SecurityScanOutlined />,
      label: 'MFA Configuration',
      requiredRoles: ["Administrator"],
      target: "/admin/twomodeauth"
    })
    menu.push({
      key: '11',
      icon: <CrownOutlined />,
      label: 'Security Configuration',
      requiredRoles: ["God"],
      target: "/admin/securityConfig"
    })
    menu.push({
      key: '12',
      icon: <LaptopOutlined />,
      label: 'PATS Control',
      requiredRoles: ["God"],
      target: "/admin/control-pats"
    })


    return menu

  }
  const [menu] = useState(getMenu)

  const CheckIfAdmin = (auth: any) => {

    let isAdmin = auth.roles != undefined && auth.roles.some(value => value === "Administrator")
    let isGod = auth.roles != undefined && auth.roles.some(value => value === "God")
    if (!isGod && !isAdmin) {
      window.location.href = auth.identityUrl
      return
    }
    setTimeout(() => {
      setAuthenticate(true)
    }, 1000);

   
  }

  if (config) {

    return <SecureContent
      config={{
        authority: config.identityServerUrl,
        redirectUri: config.serviceUrl,
        resource: config.scopes,
        clientId: config.clientId,
        silentRedirectUri: `${config.serviceUrl}/silentrefresh.html`,
      }}
      onAuthentication={(result) => { CheckIfAdmin(result); window.history.replaceState(window.history.state, '', window.location.pathname + window.location.search) }}
    >
    

      <div className={`${props.match.path}`}>
        {authenticate &&
          <ShellDisclaimer>
            <Shell availableLanguages={['es', 'en', 'pt', 'fr', 'de', 'it']} statusMode className={config.environment} v2 logo={logo} footerLogo={footerLogo} sideMenu={{ menu: menu, collapsible: true }}>
              <ShellNotification>
                <Route exact path={`${props.match.path}`} component={Home} />
                <Route exact path={`${props.match.path}/users`} component={Users} />
                <Route exact path={`${props.match.path}/users/:id`} component={UserDetail} />
                <Route exact path={`${props.match.path}/applications`} component={Applications} />
                {/*<Route exact path={`${props.match.path}/policies`} component={Policy} />*/}
                <Route exact path={`${props.match.path}/areas`} component={Areas} />
                <Route exact path={`${props.match.path}/applications/:id`} component={ApplicationDetail} />
                <Route exact path={`${props.match.path}/applications/:id/:tab`} component={ApplicationDetail} />
                <Route exact path={`${props.match.path}/applications/:applicationId/roles/:id`} component={RoleDetail} />
                <Route exact path={`${props.match.path}/applications/:applicationId/users/:id`} component={UserDetail} />
                <Route exact path={`${props.match.path}/groups`} component={Groups} />
                <Route exact path={`${props.match.path}/logs`} component={LogsView} />
                <Route exact path={`${props.match.path}/groups/:id`} component={GroupDetail} />
                <Route exact path={`${props.match.path}/pats`} component={PatList} />
                <Route exact path={`${props.match.path}/control-pats`} component={PatControl} />
                <Route exact path={`${props.match.path}/inspector`} component={PermissionInspector} />
                <Route exact path={`${props.match.path}/tenants`} component={TenantsView} />
                <Route exact path={`${props.match.path}/tenants/:id`} component={TenantDetail} />
                <Route exact path={`${props.match.path}/permissions`} component={TenantIdentityView} />
                <Route exact path={`${props.match.path}/permissions/:id`} component={IdentityGroupDetail} />
                <Route exact path={`${props.match.path}/twomodeauth`} component={TwoModeAuthView} />
                <Route exact path={`${props.match.path}/securityConfig`} component={Config} />
              </ShellNotification>
            </Shell>
          </ShellDisclaimer>
        }
        {!authenticate &&

          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              height: '100px',
              marginTop: '-50px',
              marginLeft: '-200px',
              width: '400px',
              textAlign: 'center',
            }}
          >
            <div style={{ marginLeft: '55px', marginBottom: '10px', display: 'flex' }}>
              <Spin style={{ marginRight: '10px', marginTop: '5px' }} indicator={<LoadingOutlined style={{ fontSize: 44 }} spin />} />{' '}
              <img style={{ marginTop: 12, background: 'rgb(0,0,164)', padding: '4px 4px 4px 4px' }} alt="fi-logo" src={logo} height={34} />
              <span style={{ fontSize: 18, float: 'right', marginTop: 12, marginLeft: 5, fontWeight: 'bold', color: '#0000a4' }}>
                Helping ideas grow
              </span>
            </div>

          </div>


        }

      </div>

    </SecureContent>
  } else {
    return <div
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        height: '100px',
        marginTop: '-50px',
        marginLeft: '-200px',
        width: '400px',
        textAlign: 'center',
      }}
    >
      <div style={{ marginLeft: '55px', marginBottom: '10px', display: 'flex' }}>
        <Spin style={{ marginRight: '10px', marginTop: '5px' }} indicator={<LoadingOutlined style={{ fontSize: 44 }} spin />} />{' '}
        <img style={{ marginTop: 12, background: 'rgb(0,0,164)', padding: '4px 4px 4px 4px' }} alt="fi-logo" src={logo} height={34} />
        <span style={{ fontSize: 18, float: 'right', marginTop: 12, marginLeft: 5, fontWeight: 'bold', color: '#0000a4' }}>
          Helping ideas grow
        </span>
      </div>

    </div>
  }
}
export default withTranslation()(withIdentity(withRouter(AdminRoutes)))
