import autobind from 'autobind-decorator'
import * as React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Input, Alert, Card, Button, Tag } from 'antd'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { TableModel, TableView } from 'src/core/ui/collections/table'
import ContentTitle from 'src/core/ui/content-header'
import { CommandResult } from '../../../core/stores/types'
import { PatsStore, PatSummary } from '../../../stores/pats/pats-store'
import NewPatView from './pat-new'
import { clone, formatDate } from '../../../core/utils/object'
import PatCreatedView from './pat-created'
import { Query } from '../../../core/stores/data-store'
import { container } from '../../../inversify.config'
import { useState } from 'react'
import { SearchOutlined } from '@ant-design/icons'
import TimeAgo from 'react-timeago'

// tslint:disable-next-line:no-empty-interface
interface PatListViewProps extends RouteComponentProps, WithTranslation {

}


const PatList: React.FC<PatListViewProps> = (props) => {

  const { t } = props
  const [query, setQuery] = React.useState({
    searchQuery: '', skip: 0, take: 10, orderBy: [{ field: 'name', direction: 'Ascending', useProfile: false }],
  } as Query)

  const [newPatShown, setnewPatShown] = React.useState(false)
  const [patCreatedShown, setpatCreatedShown] = useState(false)
  const [createdPatValue, setcreatedPatValue] = useState<string | undefined>()

  const currentStore = React.useMemo(() => container.get(PatsStore), [])
  const currentState = currentStore.state

  React.useEffect(() => {
    load(query)
  }, [query]) // eslint-disable-line react-hooks/exhaustive-deps


  const load = (q: Query) => {
    currentStore.load(q)
  }



  const onQueryChanged = (q: Query) => {
    setQuery(q)
  }

  const onNewItem = () => {
    setnewPatShown(true)
  }

  const onNewItemClosed = () => {
    setnewPatShown(false)
    load(query)
  }

  const onPatCreated = (patValue: string) => {
    setcreatedPatValue(patValue)
    setpatCreatedShown(true)
  }

  const onPatCreatedClosed = () => {
    setpatCreatedShown(false)
  }

  const onDeleteRow = async (item: PatSummary) => {
    await currentStore.delete(item.id)
    load(query)
  }



  const tableModel = {
    query: query,
    columns: [
      {
        sortable : true,
        field: 'name',
        title: t('Name'),
        renderer: (data) => <span>{data.name}  </span>,
        editor: (data) => <Input />
      },
      {
        field: 'expiresAt',
        title: t('Expires at'),
        renderer: (data) => <span>{data.expiresAt ? formatDate(data.expiresAt) : '-'}</span>,
        editor: (data) => <Input />
      },
      {
        field: 'lastDateOfUse',
        sortable: true,
        title: t('Last Date of Use'),
        renderer: (data) => <Tag color="green">{data.lastDateOfUse ? formatDate(data.lastDateOfUse) : '----'}</Tag>,
        editor: (data) => <Input />
      },
      {
        field: '',
        title: t(''),
        renderer: (data) => <Tag color="red"> <TimeAgo date={data.lastDateOfUse} /></Tag>,
        editor: (data) => <Input />
      },

    ],
    data: currentState.value,
    sortFields: [],
  } as TableModel<PatSummary>
  return (
    <div>
      <ContentTitle title={t("Personal Access Tokens")} />
      <Card>
        <TableView
          title={""}
          model={tableModel}
          onQueryChanged={(q: Query) => onQueryChanged(q)}
          onNewItem={onNewItem}
          onRefresh={() => setQuery(clone(query))}
          canEdit={false}
          canDelete={true}
          onDeleteRow={(item) => onDeleteRow(item)}
          canCreateNew={true}>
        </TableView>
        {
          newPatShown && <NewPatView onClose={onNewItemClosed} onCreated={onPatCreated} {...props} />
        }
        {
          patCreatedShown && <PatCreatedView onClose={onPatCreatedClosed} patValue={createdPatValue} {...props} />
        }
      </Card>
    </div>
  )
}

// Wire up the React component to the Redux store
export default withTranslation()(withRouter(PatList))
