import { UserOutlined } from "@ant-design/icons"
import { FC, useMemo } from "react"
import { withTranslation, WithTranslation } from "react-i18next"
import { Query, QueryResult } from "../core/stores/data-store"
import SelectionInput from "../core/ui/form/selectionInput"
import { container } from "../inversify.config"

import { OwnersStore } from "../stores/applications/owners-store"
import { UserSummary } from "../stores/users/users-store"

export interface OwnerReference {
  id: string
  name?: string
  email?: string
}

const OwnerEditor: FC<
  {
    appId :string
    className?: string
    valueAsItemReference: boolean
    showOnlyTeams?: boolean
    doNotShowTeams?: boolean
    disabled?: boolean
    multiple?: boolean
    clearable?: boolean
    searchType?: string
    minWidth?: number
    placeholder?: string
    value?: OwnerReference
    onChange?: (value: OwnerReference) => void
    onSelect?: (value: string) => void
  } & WithTranslation
> = ({
  t,
  className,
  disabled,
  multiple,
  searchType,
  minWidth,
  placeholder,
  clearable,
  valueAsItemReference,
  showOnlyTeams,
  doNotShowTeams,
  value,
  onChange,
  onSelect,
  i18n,
  ...props
}) => {
  const identityItemStore = useMemo(() => container.get(OwnersStore), [])
    const identityItemState = identityItemStore.state

  const getName = (o: UserSummary) => {
    return `${o.firstName ? o.firstName : ""} ${o.lastName ? o.lastName : ""} (${o.userName})`
  }
    const formatTitle = (identityItem: OwnerReference) => {
      if (!identityItem) return ''

      return identityItem?.name
    }

    let getOwner = async (searchQuery : string, value: string) => {
      let newQuery = {
        searchQuery: searchQuery,
        skip: 0,
        take: 25,
        parameters: {
          "includeIds": value,
        },
        orderBy: [{ field: 'userName', direction: 'Ascending', useProfile: false }]
      } as Query

      identityItemStore.changeUrl(props.appId)
      await identityItemStore.load(newQuery)
      return {
        items: identityItemState.items.value.map((o) => ({ id: o.id ? `${o.id}` : null, name:getName(o),  email: o.email })),
        count: identityItemState.count.value
      } as QueryResult<any>
    }

    return (
      <SelectionInput
        content={(o: any) => (
          <div>
            <UserOutlined />
            <span>&nbsp;{formatTitle(o)}</span>
          </div>
        )}
        searchable
        nullable={clearable}
        multiple={multiple}
        onSelect={(item) => {
          if (onSelect) {
            onSelect(item.id || item)
          }
        }}
        className={className}
        disabled={disabled}
        minWidth={minWidth}
        placeholder={placeholder}
        query={getOwner}
        value={value ?? undefined}
        valueAsItemReference={valueAsItemReference}
        onChange={(item) => {
          if (onChange) {
            if (multiple) {
              onChange(item ? item : null)
            } else {
              onChange(item ? { id: item.id, name: item.name, title: item.title, email: item.email } : item)
            }
          }
        }}
        {...props}
      />
    )
  }

export default withTranslation()(OwnerEditor);



