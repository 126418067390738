import React, { FC, useEffect, useState } from 'react'
import { WithTranslation } from 'react-i18next'
import { Spin, Alert, Modal, Tag, Radio } from 'antd'
import { container } from 'src/inversify.config'
import {
  AddTenantMemberBody,
  EditTenantMemberBody,
  TenantMemberDataStore,
  TenantMemberFormStore,
  TenantMember,
  TenantMemberRoleBody,
} from 'src/stores/tenants/tenant-members-store'
import { nameof } from 'src/core/utils/object'
import { formatMessage } from 'src/core/services/http.service'
import { UserSummary } from 'src/stores/users/users-store'
import Form from 'antd/lib/form'
import UserSelect from 'src/components/userEdit'
import GroupSelect from 'src/components/groupEdit'
import RolesSelect from 'src/components/roleEdit'
import ApplicationsSelect from 'src/components/applicationEdit'
import { IdentityType } from 'src/stores/tenants/common'
import { Query } from 'src/core/stores/data-store'
import { TableModel, TableView } from 'src/core/ui/collections/table'
import { EditOutlined, TeamOutlined, UserOutlined } from '@ant-design/icons'
import { ApplicationItemStore } from 'src/stores/applications/applications-store'

interface AddMemberDialogFormData {
  identityType: IdentityType
  application: { value: string; label: string }
  user: any
  group: any
  roles: { value: string; label: string }[]
}

export const AddMemberDialog: FC<{ entityId: string; onClose: (cancel: boolean) => void } & WithTranslation> = ({ t, entityId, onClose }) => {
  const [form] = Form.useForm<AddMemberDialogFormData>()
  const [identityTypeValue, setIdentityTypeValue] = useState<IdentityType>(IdentityType.User)
  const [selectedApplication, setSelectedApplication] = useState<any>(undefined)

  const entityMemberStore = container.get<TenantMemberFormStore>(TenantMemberFormStore)
  const entityMemberState = entityMemberStore.state

  const [confirmLoading, setConfirmLoading] = useState(false)

  useEffect(() => {
    entityMemberStore.setTenantId(entityId)
  }, [entityId]) // eslint-disable-line react-hooks/exhaustive-deps

  const onAdd = async () => {
    setConfirmLoading(true)
    let item: AddMemberDialogFormData
    try {
      item = (await form.validateFields()) as AddMemberDialogFormData
    } catch {
      setConfirmLoading(false)
      return
    }
    const identity = item.identityType === IdentityType.User ? item.user.id : item.group.id
    const displayName = item.identityType === IdentityType.User ? item.user.displayName : item.group.name
    const email = item.identityType === IdentityType.User ? item.user.email : ""
    const body: AddTenantMemberBody = {
      identityType: item.identityType,
      identityId: identity,
      displayName: displayName,
      email : email,
      isInheritable: true,
      roles: item.roles ? item.roles.map((r) => ({ roleId: r.value, roleName: r.label, applicationId: item.application.value, applicationName: item.application.label })) : [],
    }
    let response = await entityMemberStore.addTenantMember(body)
    if (response?.isSuccess) {
      form.resetFields()
      onClose(response)
    }
    setConfirmLoading(false)
  }
  const onChangeApplication = (appValue: any) => {
    setSelectedApplication(appValue ? appValue.value : undefined)
    form.resetFields(["roles"])
  }


  return (
    <Modal
      confirmLoading={confirmLoading}
      width={600}
      maskClosable={false}
      title={t('Add Member to Tenant')}
      visible={true}
      onOk={onAdd}
      onCancel={() => onClose(true)}
      cancelText={t('Cancel')}
      okText={t('Ok')}
    >
      {entityMemberState.errorMessage.get() && <Alert type="error" message={t(entityMemberState.errorMessage.value || '')} />}
      <Spin spinning={entityMemberState.isBusy.get()}>
        <Form size="middle" form={form} layout="vertical" autoComplete="off" initialValues={{ identityType: identityTypeValue }}>
          <Form.Item label={t('Identity')} required>
            <Form.Item name={nameof<AddMemberDialogFormData>('identityType')} rules={[{ required: true }]}>
              <Radio.Group
                onChange={(e) => {
                  setIdentityTypeValue(e?.target?.value ?? 'User')
                  form.setFieldsValue({ ...form.getFieldsValue(), user: undefined, group: undefined })
                }}
              >
                <Radio value={IdentityType.User}>{t('User')}</Radio>
                <Radio value={IdentityType.Group}>{t('Group')}</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item noStyle>
              {identityTypeValue === 'User' && (
                <Form.Item noStyle name={nameof<AddMemberDialogFormData>('user')} label={t('User')} rules={[{ required: true }]}>
                  <UserSelect valueAsItemReference />
                </Form.Item>
              )}
              {identityTypeValue === 'Group' && (
                <Form.Item noStyle name={nameof<AddMemberDialogFormData>('group')} label={t('Group')} rules={[{ required: true }]}>
                  <GroupSelect valueAsItemReference />
                </Form.Item>
              )}
            </Form.Item>
            {/*<Form.Item label={t('Application')} name={nameof<AddMemberDialogFormData>('application')} rules={[{ required: true }]}>
                            <ApplicationsSelect onChange={(appValue) => onChangeApplication(appValue)} />
                        </Form.Item>
                        <Form.Item label={t('Roles')} name={nameof<AddMemberDialogFormData>('roles')} rules={[{ required: true }]}>
                            <RolesSelect applicationId={selectedApplication} />
                        </Form.Item>
                        */}
          </Form.Item>

        </Form>
      </Spin>
    </Modal>
  )
}
