import React, { FC, useEffect, useState } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import {
  Row, Col, Form, Spin, Input, Button, Alert, Modal,
  AutoComplete
} from 'antd'
import { container } from 'src/inversify.config'
import { EditTenant, TenantItem, TenantItemStore } from 'src/stores/tenants/tenants-store'
import { nameof } from 'src/core/utils/object'
import TenantHierarchyPath from './tree-section/tenant-hierarchy-path'
import { formatMessage } from 'src/core/services/http.service'
import { AreasStore } from 'src/stores/areas/areas-store'
import { Query } from 'src/core/stores/data-store'
import TenantSelect from 'src/components/tenantEdit'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { IdentityProps, withIdentity } from 'src/core/services/authentication'
import AreaEditor from 'src/components/areaEdit'
import { withCache } from 'src/core/services/cache.service'
const { confirm } = Modal


interface TenantDetailGeneralTabFormData {
  title: string
  taxNumber: string
  areaId: any
  viesNumber: string
  parent: { value: string; label: string }
}
const TenantDetailsGeneralTab: FC<{ tenantId: string, onChange: () => void } & WithTranslation & IdentityProps> = ({ t, tenantId, onChange, identity }) => {
  const entityStore = container.get<TenantItemStore>(TenantItemStore)
  const entityStoreState = entityStore.state

  const [IsAdmin] = useState(identity.roles?.any(r => r === "Administrator"))
  const [IsUser] = useState(identity.roles?.any(r => r === "User"))
  const [IsManager] = useState(identity.roles?.any(r => r === "Manager"))

  const [isEditMode, changeEditMode] = useState<boolean>(false)
  const [isChangeParentMode, changeParentMode] = useState<boolean>(false)
  const [error, setError] = useState<string | undefined>('')
  const [form] = Form.useForm<TenantDetailGeneralTabFormData>()

  const areasStores = container.get(AreasStore)
  const [options, setOptions] = useState([])



  const entityParent = entityStoreState.item.get()?.hierarchyItems.slice(-2, -1)[0]

  useEffect(() => {
    entityStore.clear()
    form.resetFields()
    load();
  }, [tenantId]) // eslint-disable-line react-hooks/exhaustive-deps


  const load = async () => {
    await entityStore.load(tenantId, true, false)
  }

  const save = async (editData: EditTenant) => {
    let response = await entityStore.save(tenantId, (editData as unknown) as TenantItem)
    if (response?.isSuccess) {
      await entityStore.load(tenantId, true, false)
      form.resetFields()
      changeEditMode(false)
      changeParentMode(false)
    } else setError(formatMessage(response))
  }

  const onSave = async () => {
    let item: TenantDetailGeneralTabFormData
    try {
      item = (await form.validateFields()) as TenantDetailGeneralTabFormData
    } catch {
      return
    }

    const editData: EditTenant = {
      title: item.title,
      areaId: item.areaId.id,
      taxNumber: item.taxNumber,
      viesNumber: item.viesNumber,
      parentId: item.parent?.value,
    }

    if (editData.parentId !== entityParent?.id) {
      confirm({
        title: t('Are you sure you want to change this entity parent?'),
        icon: <ExclamationCircleOutlined />,
        content: t('This action can affect users permissions'),
        onOk: async () => {
          await save(editData)
          onChange()
        },
        onCancel: () => {
          form.setFieldsValue({ ...form.getFieldsValue(), parent: entityParent ? { value: entityParent.id, label: entityParent.title || entityParent.title.trim().length != 0 ? entityParent.title : t("(Empty name)") } : undefined })
        },
      })
    } else {
      await save(editData)
    }


  }

  return (
    <div style={{ padding: 20 }}>
      {entityStoreState.errorMessage.get() && <Alert type="error" message={t(entityStoreState.errorMessage.value || '')} />}
      <Spin spinning={entityStoreState.isBusy.get()}>
        {entityStoreState.item.get() ? (
          <Form
            size="middle"
            form={form}
            layout="vertical"
            autoComplete="off"
            initialValues={{
              title: entityStoreState.item.get().title,
              areaId: {
                id: entityStoreState.item.get().areaId, name: entityStoreState.item.get().areaName
              },
              taxNumber: entityStoreState.item.get().taxNumber,
              viesNumber: entityStoreState.item.get().viesNumber,
              parent: entityParent ? { value: entityParent.id, label: entityParent.title || entityParent.title.trim().length != 0 ? entityParent.title : t("(Empty name)") } : undefined,
            }}
            requiredMark={isEditMode}
            style={{ width: '60%' }}
          >
            <Form.Item
              name={nameof<TenantDetailGeneralTabFormData>('title')}
              label={t('Title')}
              rules={[{ required: true, message: t('Please enter a title') }]}
            >
              {isEditMode ? <Input autoComplete="off" /> : <span>{entityStoreState.item.get().title}</span>}
            </Form.Item>

            <Form.Item name={nameof<TenantDetailGeneralTabFormData>('areaId')} label={t('Area')} rules={[{ required: true, message: t('Area is required') }]}>
              <AreaEditor valueAsItemReference disabled={!isEditMode} />
            </Form.Item>
            <Form.Item style={{ display: isChangeParentMode ? "initial" : "none" }}
              label={t('Parent')}
              name={nameof<TenantDetailGeneralTabFormData>('parent')}
              
            >
              <TenantSelect excludeDescendand={entityStoreState.item.get().id} />
            </Form.Item>
            {!isChangeParentMode && <Form.Item label={t('Hierarchy')}>
              <div style={{ display: "flex" }}>
                <TenantHierarchyPath hierarchyItems={[...entityStoreState.item.get().hierarchyItems]} />
                {(IsAdmin || IsManager) && <Button style={{ marginLeft: "20px" }} danger disabled={entityStoreState.isBusy.value} onClick={() => { changeParentMode(true); changeEditMode(true) }}>
                  {t('Change Parent Tenant')}
                </Button>}
              </div>

            </Form.Item>}
            
              <Form.Item name={nameof<TenantDetailGeneralTabFormData>('taxNumber')} label={t('CIF')}>
                {isEditMode ? <Input autoComplete="off" /> : <span>{ entityStoreState.item.get().taxNumber }</span>}
            </Form.Item>
            <Form.Item name={nameof<TenantDetailGeneralTabFormData>('viesNumber')} label={t('European VAT')}>
              {isEditMode ? <Input autoComplete="off" /> : <span>{entityStoreState.item.get().viesNumber}</span>}
            </Form.Item>
           
            {/* {entityStoreState.item.get().entityType === TenantType.OrganizationalUnit && (
          
           )} */}
            <Form.Item>
              {isEditMode ? (
                <Row gutter={40} justify="end" align="middle" style={{ marginTop: 10 }}>
                  <Col span={6} style={{ display: "flex" }}>
                    <Button style={{ marginRight: "10px" }}
                      htmlType="button"
                      disabled={entityStoreState.isBusy.value}
                      onClick={() => {
                        form.resetFields()
                        changeParentMode(false)
                        changeEditMode(false)
                      }}
                    >
                      {t('Cancel')}
                    </Button>

                    <Button type="primary" disabled={entityStoreState.isBusy.value} onClick={onSave}>
                      {t('Ok')}
                    </Button>
                  </Col>

                </Row>
              ) : (
                <> {(IsAdmin || IsManager) && <Row gutter={[16, 16]} justify="end" align="middle" style={{ marginTop: 10 }}>
                  <Col span={3}>
                    <Button type="primary" disabled={entityStoreState.isBusy.value} onClick={() => changeEditMode(true)}>
                      {t('Edit')}
                    </Button>
                  </Col>
                </Row>}
                </>
              )}
            </Form.Item>
          </Form>
        ) : (
          <div style={{ width: '60%', height: 100 }}></div>
        )}
      </Spin>
    </div>
  )
}

export default withCache(withIdentity(withTranslation()(TenantDetailsGeneralTab)))