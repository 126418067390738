import i18next from 'i18next'
import lang from './i18n.generated'
import coreLang from 'src/core/i18n/i18n.generated'

const arrayLanguajes = Object.keys(lang.data)

let updatedResource: any = {}

for (var i = 0; i < arrayLanguajes.length; i++) {
  var idiom = arrayLanguajes[i]
  let mergeTranslations = (lang.data as any)[idiom]
  if ((coreLang.data as any)[idiom]) {
    const coreLangKeys = Object.keys((coreLang.data as any)[idiom])
    for (var j = 0; j < coreLangKeys.length; j++) {
      var key = coreLangKeys[j]
      if (!(lang.data as any)[idiom][key]) {
        mergeTranslations[key] = (coreLang.data as any)[idiom][key]
      }
    }
  }
  const obj = {
    translations: mergeTranslations
  }
  updatedResource[idiom] = obj
}


i18next.init({
  lng: 'en',
  // we init with resources
  resources: updatedResource,
  fallbackLng: lang.source,
  debug: false,

  // have a common namespace used around the full app
  ns: ['translations'],
  defaultNS: 'translations',

  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ','
  },

  react: {
    wait: true
  }
})

export default i18next
