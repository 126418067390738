import { Avatar, Divider, Layout, Row, Card, Tabs, Spin, Col } from 'antd'
const { Content } = Layout
const TabPane = Tabs.TabPane
import { withTranslation, WithTranslation } from 'react-i18next'
import LoginLocalView from './login-local'
import FooterView from "src/components/footer"
import { LeftOutlined, LoadingOutlined } from '@ant-design/icons'
import { FC, useState } from 'react'
import whiteLogo from 'src/assets/FIB22_FI_Connect_Logo_2.png'
import src from 'src/core/assets/images/helpDesk.png'
import footerLogo from 'src/assets/logo-simple.png'
import LanguageBox from 'src/components/language-box'
import LoginButton from './login-button'
import CguContentView from 'src/components/cguContentViewer'
import "./login.scss"
import { AppEnvironment } from '../../inversify.config'
import { env } from 'process'

const login_links = "https://figroup.freshworks.com/login/auth/contacts?client_id=78368727158800390&redirect_uri=https%3A%2F%2Ffigroup.freshdesk.com%2Ffreshid%2Fcustomer_authorize_callback"
interface LoginViewProps extends WithTranslation {

}

const LoginView: FC<LoginViewProps> = (props) => {
  const { t } = props
  const [localLogin, setLocalLogin] = useState(false)
  const [showCgu, setShowCgu] = useState(false)
  const [loginSuccess, setLoginSuccess] = useState(false)

  const handleLoginOnChange = isLogged => {
    setLoginSuccess(isLogged)
  }
  const environmet = AppEnvironment

  return <Layout className={"login-layout " + environmet}>
    <Content className="contentLayout">
      <Row gutter={16} align="middle">
        <Col lg={12} sm={24} xs={24} className='intro-col'>
          <Row>
            <img src={whiteLogo} title="logo" />
            <p>{t("FI Connect is a digital applications HUB created by FI Group that seeks to transform and optimize the relationship we have with our customers.")}</p>
            <LanguageBox availableLanguages={['es', 'en', 'pt', 'fr', 'de', 'it']} />
          </Row>
        </Col>

        <Col lg={12} sm={24} xs={24} className='login-col'>
          <Card className='card-login'>
            <span>{t("Login")} FI Connect</span>
            <Row>
              <Tabs renderTabBar={() => <></>} activeKey={localLogin ? "localLogin" : "login"} style={{ width: '100%' }}>
                <TabPane tab="login" key='login'>
                  <LoginButton provider='Local' onLocalLogin={() => setLocalLogin(true)} primary />
                  <Divider>{t('Or with the following providers')}</Divider>
                  <LoginButton provider='Microsoft' />
                </TabPane>

                <TabPane tab="localLogin" key='localLogin'>
                  <h4 className='title-local'>{t('Local user')}</h4>
                  <LoginLocalView loginOnChange={handleLoginOnChange} />
                  {
                    !loginSuccess &&
                    <p className="get-back-login clickable" onClick={() => setLocalLogin(false)}>
                      <LeftOutlined className='clickable' /> {t('Select another authentication method')}
                    </p>
                  }
                </TabPane>
              </Tabs>
            </Row>
            {
              !loginSuccess ?
                <Row className='login-links'>
                  <a target="_blank" href={login_links}>
                    <Avatar src={src} />
                    {props.t("Problems to access? Contact Us!")}
                  </a>
                  <a onClick={() => setShowCgu(true)}>{t('Terms of service')}</a>
                </Row>
                :
                <Row className="login-loader">
                  <Spin indicator={<LoadingOutlined style={{ fontSize: 44 }} spin />} />{' '}
                  <span>{t('Logging in')}</span>
                </Row>
            }
          </Card>
        </Col>
      </Row>
    </Content>
    <FooterView footerLogo={footerLogo} />
    {showCgu && <CguContentView onClose={() => setShowCgu(false)} />}
  </Layout>
}

export default withTranslation()(LoginView)