import { FC, useState, useMemo, useEffect } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Input, Card, Button, Row, Col, Tooltip, Select, Tag, message, Avatar, Popconfirm, notification } from 'antd'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { UsersStore, UserSummary } from 'src/stores/users/users-store'
import { TableModel, TableView, ItemState } from 'src/core/ui/collections/table'
import { formatDate, formatBoolean, clone } from 'src/core/utils/object'
import NewUserView from './user-new'
import ContentTitle from 'src/core/ui/content-header'
import Link from 'src/core/ui/link'
import ApplicationEditor from 'src/components/applicationEdit'
import HttpService from 'src/core/services/http.service'
import FileSaver from 'file-saver'
import { BaseAppUrl, container } from 'src/inversify.config'
import { formatMessage } from 'src/core/services/http.service'
import { Query } from 'src/core/stores/data-store'
import buildQuery from 'odata-query'
import { getProperties } from 'src/core/utils/object'
import { CheckOutlined, CloseOutlined, DownloadOutlined, GoldOutlined, LoadingOutlined, LockOutlined, MailOutlined, UserAddOutlined, UserOutlined, UnlockOutlined, FormOutlined } from '@ant-design/icons'
import { buildUrl } from 'src/components/util'
import { CacheProps, withCache } from 'src/core/services/cache.service'
import BulkUploadMenu from 'src/components/bulk-upload/bulk-upload-menu'
import AreaSelect from 'src/components/areaEdit'

const { Option } = Select

interface UsersViewProps extends RouteComponentProps, CacheProps, WithTranslation {

}

interface Result {
  message: string
  user: string
}

const Users: FC<UsersViewProps> = (props) => {

  const { t } = props
  const usersStore = useMemo(() => container.get(UsersStore), [])
  const usersStoreState = usersStore.state
  const [isBusy, setIsBusy] = useState(false)
  const [newUserShown, setNewUserShown] = useState(false)
  const httpService = container.get(HttpService)
  const [query, setQuery] = useState({
    searchQuery: '',
    orderBy: [{ field: 'modifiedOn', direction: 'Descending', useProfile: false }],
    skip: 0,
    take: 10,
    odataObject: {
      filter: {}
    },
    parameters: {

    }
  } as Query)

  const cacheKey = "IDENTITY-USERS-LIST"

  const [clientId, setClientId] = useState<string | undefined>(undefined)
  const [lockFilter, setLockFilter] = useState<any>()
  const [areaFilter, setAreaFilter] = useState<any>()
  const [initPagination, setInitPagination] = useState(false)


  useEffect(() => {
    var queryStored = props.cache.getWithCustomKey(cacheKey)
    if (queryStored && queryStored.query)
      setQuery(queryStored.query)
    if (queryStored && queryStored.area)
      setAreaFilter(queryStored.area)
    if (queryStored && queryStored.locked)
      setLockFilter(queryStored.locked)
    usersStore.load(Build(queryStored?.query || query, (queryStored?.locked) || lockFilter, queryStored?.area || areaFilter))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps


  const onDownloadExcel = async () => {
    const downloadExcelUrl: string = 'api/v1/admin/users/export'
    const downloadExcelFilename: string = 'Users.xlsx'
    setIsBusy(true)
    const result = await httpService.get(`${downloadExcelUrl}?${buildUrl(query)}`, {
      responseType: 'arraybuffer'
    })
    const blob = new Blob([result.data as any], { type: result.headers['content-type'] });
    (FileSaver as any).saveAs(blob, downloadExcelFilename)
    setIsBusy(false)
  }

  const load = (q: Query) => {
    usersStore.load(q)
  }

  const onDeleteRow = async (item: UserSummary) => {
    await usersStore.delete(item.id)
    load(query)
  }


  const inviteUser = async (email) => {
    try {
      const result = await usersStore.inviteUser(email)
      if (result && result.isSuccess) {
        notification.success({
          message: t('Successful action'),
          description: t('Invitation mail sent successfully.'),
        })
      } else {
        notification.error({
          message: t('An error ocurred'),
          description: t('Failed to send invitation mail. Please try again later.'),
        })
      }
      load(query)
    } catch (error) {
      console.error("ERROR CATCH = ", error)
      notification.error({
        message: t('An error ocurred'),
        description: t('Failed to send invitation mail. Please try again later.'),
      })
    }
  }


  const [messageApi, contextHolder] = message.useMessage()
  const unlockUser = async (id: string) => {
    httpService.get<Result>(`${BaseAppUrl}/api/v1/account/unlock/${id}`)
      .then(result => {
        messageApi.open({
          type: 'success',
          content: <>{t(`The user`)} {result.data.user} {t(`has been unlocked`)}</>
        })
        load(query)
      })
      .catch(error => { console.log(error) })
  }

  const lockUser = async (id: string) => {
    httpService.get<Result>(`${BaseAppUrl}/api/v1/account/lock/${id}`)
      .then(result => {
        messageApi.open({
          type: 'success',
          content: <>{t(`The user`)} {result.data.user} {t(`has been locked`)}</>
        })
        load(query)
      })
      .catch(error => { console.log(error) })
  }
  const GetStatus = (user: UserSummary) => {
    if (user.draft) {
      return <span>
        <Tooltip title={t("Draft")}><Avatar style={{ background: 'blue' }} size={30} icon={<FormOutlined />} /></Tooltip>
      </span>
    }
    if (user.locked) {
      return <span className='button-unlock'>
        <Popconfirm
          placement="bottomLeft"
          title={t("Do you want to unlock this user?")}
          onConfirm={() => unlockUser(user.id)}
          okText="Yes"
          cancelText="No"
        >

          <Tooltip title={t("Click to unlock")}><Avatar style={{ background: 'red' }} size={30} icon={<UnlockOutlined />} /></Tooltip>
        </Popconfirm>
      </span>
    }
    return <span className='button-unlock'>
      <Popconfirm
        placement="bottomLeft"
        title={t("Do you want to lock this user?")}
        onConfirm={() => lockUser(user.id)}
        okText="Yes"
        cancelText="No"
      >
        <Tooltip title={t("Click to lock user")}><Avatar style={{ background: 'green' }} size={30} icon={<UserOutlined />} /></Tooltip>
      </Popconfirm>
    </span >

  }

  const GetTitle = (user: UserSummary) => {
    if (user.draft) {
      return <span>  <LockOutlined /> &nbsp;<span style={{ textDecoration: 'line-through' }}>{user.userName} </span> </span>
    }
    return <span><Link to={`/admin/users/${user.id}`}>{user.locked ? <LockOutlined /> : <UserOutlined />} &nbsp;<span style={{ textDecoration: user.locked ? 'line-through' : 'none' }}>{user.userName} </span></Link> </span>

  }

  const tableModel = {
    query: query,
    columns: [
      {
        align: 'center',
        field: 'locked',
        title: t('Status'),
        renderer: (data) => GetStatus(data),
      },
      {
        sortable: true,
        centered: true,
        field: 'userName',
        title: t('User name'),
        renderer: (data) => GetTitle(data)
      },

      {
        field: 'firstName',
        title: t('First name'),
        renderer: (data) => <span>{data.firstName}</span>,
        editor: (data: any, onChange: any) => <Input value={data.firstName} onChange={(e) => { data.firstName = e.target.value }} />
      },
      {
        field: 'lastName',
        title: t('Last name'),
        renderer: (data) => <span>{data.lastName}</span>,
        editor: (data: any, onChange: any) => <Input value={data.lastName} onChange={(e) => { data.lastName = e.target.value }} />
      },
      {
        field: 'email',
        title: t('Email'),
        renderer: (data) => <span>{data.email}</span>,
        editor: (data: any, onChange: any) => <Input value={data.email} onChange={(e) => { data.email = e.target.value }} />
      },
      {
        field: 'areaName',
        title: t('Area'),
        renderer: (data) => <span>  <GoldOutlined />&nbsp;{data.areaName}</span>,
      },
      {
        align: 'center',
        field: 'validateScopes',
        title: t('Restricted'),
        renderer: (data) => <span>{formatBoolean(data.validateScopes, t)}</span>
      },

      {
        sortable: true,
        field: 'modifiedOn',
        title: t('Modified on'),
        renderer: (data) => <span>{formatDate(data.modifiedOn)}</span>,
      },
      {
        field: 'invite',
        title: t(''),
        renderer: (data) => <span>{data.draft ? <></> : <Tooltip title={t("Send email invite")}><MailOutlined onClick={() => inviteUser(data.email)} style={{ marginLeft: 15 }} /></Tooltip>}</span>
      },
    ],
    data: usersStoreState.value,
    sortFields: [
      { field: 'userName', text: t('Username'), useProfile: false },
      { field: 'modifiedOn', text: t('Modified on'), useProfile: false }
    ],
  } as TableModel<UserSummary>

  const bulkUploadOptions = [
    {
      optionLabel: 'Bulk Insert Users',
      modalName: 'Bulk Insert Users',
      bulkInsertTemplateName: 'Template import users',
      bulkInsertTemplateUrl: `api/v1/admin/users/template`,
      bulkInsertUrl: `api/v1/admin/users/bulk_insert`,
    }]



  const Build = (query: Query, locked: any, area: any): Query => {
    console.log(query, locked, area);
    if (locked) {
      query.parameters.locked = locked
    }
    else {
      query.parameters.locked = undefined
    }

    if (area) {
      query.parameters.area = area
    }
    else {
      query.parameters.area = undefined
    }
    console.log(query);
    props.cache.saveWithCustomKey(cacheKey, { query: query, locked: locked, area: area })
    return query
  }

  const lockedFilterChange = (locked: any) => {
    setInitPagination(!initPagination)
    setLockFilter(locked)
    var newquery = query
    newquery.skip = 0
    setQuery(newquery)
    load(Build(newquery, locked, areaFilter))
  }


  const rightToolbar = (
    <>
      <li>
        <Select value={lockFilter}
          allowClear placeholder={t("Status")}
          style={{ width: 140 }}
          onChange={(value) => lockedFilterChange(value)}
        >
          <Option value={true}>
            <span><LockOutlined /> {t("Locked")}</span>
          </Option>

          <Option value={false}>
            <span><UnlockOutlined /> {t("Unlocked")}</span>
          </Option>
        </Select>
      </li>


      <li>
        <AreaSelect minWidth={200} value={areaFilter} placeholder={t("Select Area...")} valueAsItemReference onChange={(value) => {
          setInitPagination(!initPagination)
          let newQuery = query
          newQuery.filter = newQuery.filter || {}
          newQuery!.filter['areaId'] = value ? { eq: { type: 'guid', value: value.id } } : undefined
          setAreaFilter(value ? value.id : undefined)
          newQuery.skip = 0
          setQuery(newQuery)
          load(Build(newQuery, lockFilter, value))
        }
        } />
      </li>

      <li>
        <Tooltip placement="topLeft" title={t('Export to Excel')}>
          <Button onClick={e => onDownloadExcel()} disabled={isBusy}>
            {isBusy && <LoadingOutlined spin />}
            {!isBusy && <DownloadOutlined />}
          </Button>
        </Tooltip>
      </li>

    </>
  )

  return (
    <div>
      {contextHolder}
      <ContentTitle title={t("Users")} />
      <Card>
        <TableView
          rightToolbar={rightToolbar}
          leftToolbar={
            <Row gutter={5}>
              <Col>
                <BulkUploadMenu options={bulkUploadOptions} />
              </Col>
            </Row>
          }

          model={tableModel}
          onQueryChanged={(query: Query) => {
            setQuery(query)
            load(Build(query, lockFilter, areaFilter))
          }}
          onNewItem={() => setNewUserShown(true)}
          onRefresh={() => usersStore.load(query)}
          canDelete={true}
          onDeleteRow={(item) => onDeleteRow(item)}
          error={usersStoreState.errorMessage.value && formatMessage(usersStoreState.errorMessage.value)}
          canCreateNew={true}
          initPagination={initPagination}
        >
        </TableView>
        {
          newUserShown && <NewUserView {...props} onClose={() => { setNewUserShown(false); load(query) }} />
        }
      </Card>
    </div>
  )
}

// Wire up the React component to the Redux store
export default withCache(withTranslation()(withRouter(Users)))

