//this file was added to fix new user area issue

import { GoldOutlined, IdcardOutlined } from "@ant-design/icons"
import { Select, Spin } from "antd"
import { FC, useMemo, useEffect, useState } from "react"
import { withTranslation, WithTranslation } from "react-i18next"
import { Query, QueryResult } from "../core/stores/data-store"
import SelectionInput from "../core/ui/form/selectionInput"
import { container } from "../inversify.config"
import debounce from 'lodash/debounce'

import { AreasStore, AreaItem } from "../stores/areas/areas-store"
import Search from "antd/lib/input/Search"


export interface AreaReference {
  id: string
  name: string
  default : boolean
}

const AreaEditor: FC<
  {
    idMode?: boolean
    disabled?: boolean
    searchType?: string
    minWidth?: number
    placeholder?: string
    value?: string | AreaReference
    valueAsItemReference: boolean
    onChange?: (value: AreaReference) => void
    onSelect?: (value: string) => void
  } & WithTranslation
> = ({ t, disabled, searchType, minWidth, placeholder, value, onChange, onSelect, i18n, ...props }) => {
  const currentStore = useMemo(() => container.get(AreasStore), [])
  const currentState = currentStore.state

  const formatTitle = (application: AreaItem) => {
    return application?.name
  }

  let getArea = async (query: string, value: AreaItem) => {
    await currentStore.load({
      searchQuery: query,
      skip: 0,
      parameters: {
        includeIds: value ? [value.id ?? value as any ] : undefined,
      },
      take: 1000,
      
    })
    return {
      items: currentState.items.value.map((o) => ({ id: o.id ? `${o.id}` : null, title: formatTitle(o), default : o.default })),
      count: currentState.count.value
    } as QueryResult<any>
  }

  return (
    <SelectionInput
      content={(o: any) => (
        <div>
          <GoldOutlined />
          <span>&nbsp;{o.title}</span>
        </div>
      )}
      searchable
      nullable
      multiple={false}
      onSelect={(item) => {
        if (onSelect) {
          onSelect(item.id || item)
        }
      }}
      valueAsItemReference
      disabled={disabled}
      minWidth={minWidth}
      placeholder={placeholder}
      query={getArea}
      value={value}
      onChange={(item) => {
        if (onChange) {
          onChange(item ? { id: item.id, name: item.title, default : item.default} : null)
        }
      }}
      {...props}
    />
  )
}

export default withTranslation()(AreaEditor)









