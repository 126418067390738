import * as React from 'react'
import { Form, Spin, Input, Checkbox, Modal, Row, Col, Alert } from 'antd';
let FormItem = Form.Item;
import autobind from 'autobind-decorator'
import { RoleItemStore, NewRoleItem } from 'src/stores/roles/roles-store'
import { withTranslation, WithTranslation } from 'react-i18next';
import { nameof } from 'src/core/utils/object';
import TextArea from 'antd/lib/input/TextArea';
import { container } from '../../../../inversify.config';
import { useState } from 'react';

interface NewRoleViewProps {
    onClose: () => void
    applicationId: string
}


const NewRoleView : React.FC<NewRoleViewProps  & WithTranslation> = (props) => {

  const [form] = Form.useForm<NewRoleItem>()
  const [initialValues, setInitialValues] = React.useState<NewRoleItem | undefined>({ description : "" , id : "" , name :"" })

  const currentStore = React.useMemo(() => container.get(RoleItemStore), [])
  const currentState = currentStore.state
  const [confirmLoading, setConfirmLoading] = useState(false);


  const onCancel = () => {
    props.onClose()
  }

  const onCreate = async () => {
    setConfirmLoading(true)
    let item: NewRoleItem
    try {
      item = (await form.validateFields()) as NewRoleItem
    } catch {
      setConfirmLoading(false)
      return
    }
    currentStore.setApplicationId(props.applicationId)
    let response = await currentStore.create(item)
    if (response) {
      form.resetFields()
      props.onClose()
    }
    setConfirmLoading(false)
  }

  const { t } = props
        return (
            <Modal
              maskClosable={false}
              visible
              onCancel={() => onCancel()}
              confirmLoading={confirmLoading}
              onOk={() => onCreate()}
              closable={false}
              title={t('New role')}
              cancelText={t('Cancel')}
              okText={t('Ok')}
            >
            {currentState.errorMessage.get() && <Alert type="error" message={t(currentState.errorMessage.value || '')} />}
            <Spin spinning={currentState.isBusy.get()}>
              <Form layout="vertical" form={form} initialValues={initialValues}>
                <FormItem label={t("Name")}
                  name={nameof<NewRoleItem>('name')}
                  rules={[{ required: true, message: t('Please input your API title!') }] }>
                          
                                <Input placeholder={t("Name")} />
                        </FormItem>
                <FormItem label={t("Description")}
                  name={nameof<NewRoleItem>('description')}
                >
                                <TextArea placeholder={t("Description")} />
                        </FormItem>
                    </Form>
                </Spin>
            </Modal>
        );
    }

export default withTranslation()(NewRoleView) 
