import autobind from 'autobind-decorator'
import * as React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Input, Alert, Card } from 'antd'
import { RolesStore, RoleSummary } from 'src/stores/roles/roles-store'
import { TableModel, TableView } from 'src/core/ui/collections/table'
import NewRoleView from './role-new'
import Link from 'src/core/ui/link'
import { CommandResult } from 'src/core/stores/types'
import { RouteComponentProps, withRouter } from 'react-router'
import { Query } from '../../../../core/stores/data-store'
import { useMemo, useState } from 'react'
import { BaseAppUrl, container } from '../../../../inversify.config'
import { clone } from '../../../../core/utils/object'
import { formatMessage } from '../../../../core/services/http.service'
import { IdcardOutlined } from '@ant-design/icons'
import { IdentityProps, withIdentity } from '../../../../core/services/authentication'

// tslint:disable-next-line:no-empty-interface
interface RolesViewProps extends RouteComponentProps, WithTranslation {
  applicationId: string
}

interface RolesViewState {
  query: Query
  newUserShown: boolean
  roleId: string | undefined
  scope: string | undefined
}

const Roles: React.FC<RolesViewProps & IdentityProps> = (props) => {

  const [query, setQuery] = useState({
    searchQuery: '',
    orderBy: [{ field: 'name', direction: 'Ascending', useProfile: false }],
    skip: 0,
    take: 10,
  } as Query)

  const [newRoleShown, SetnewRoleShown] = useState(false)
  const currentStore = useMemo(() => container.get(RolesStore), [])
  const currentState = currentStore.state

  React.useEffect(() => {
    load(query)
  }, [query]) // eslint-disable-line react-hooks/exhaustive-deps


  const load = (q: Query) => {
    currentStore.setApplicationId(props.applicationId)
    currentStore.load(q)
  }

  const onDeleteRow = async (item: RoleSummary) => {
    await currentStore.delete(item.id)
    load(query)
  }

  const onSaveItem = async (item: RoleSummary) => {
    await currentStore.update(item.id, item)
    load(query)
  }



  const { t } = props as any
  const tableModel = {
    query: query,
    columns: [
      {
        field: 'name',
            title: t('Name'),
        sortable: true,
        renderer: (data) => <span><Link to={{ pathname: `/admin/applications/${props.applicationId}/roles/${data.id}`, state: { titles: [...(props.location.state ? (props.location.state as any).titles || [] : []), { url: `${BaseAppUrl}${props.match.url}/${data.id}`, title: data.name }] } }}><IdcardOutlined />&nbsp;<span>{data.name}</span></Link></span>,
        editor: (data) => <Input />
      },
      {
        field: 'description',
        title: t('Description'),
        renderer: (data) => <span>{data.description}</span>,
        editor: (data) => <Input />
      }
    ],
    data: currentState.value,
    sortFields: [],
  } as TableModel<RoleSummary>
  return (<Card>

    <TableView
      model={tableModel}
      onQueryChanged={(q: Query) => setQuery(q)}
      onNewItem={() => SetnewRoleShown(true)}
          onRefresh={() => setQuery(clone(query))}
      canEdit={false}
      canDelete={true}
      onDeleteRow={onDeleteRow}
      error={currentState.errorMessage.value && formatMessage(currentState.errorMessage.value)}
      canCreateNew={props.identity.roles != undefined && props.identity.roles.some(value => value === "Administrator")}>
    </TableView>
  {
      newRoleShown && <NewRoleView onClose={() => { SetnewRoleShown(false); load(query) }} applicationId={props.applicationId} />
  }
  </Card>)
}

export default withTranslation()(withRouter(withIdentity(Roles)))
