import * as React from 'react'
import { Modal, Button, Tooltip } from 'antd'
import autobind from 'autobind-decorator'
import { withTranslation, WithTranslation } from 'react-i18next'
import { useState } from 'react'
import { CopyOutlined } from '@ant-design/icons'

interface PatCreatedViewProps extends WithTranslation {
  onClose: () => void
  patValue: string | undefined
}

interface PatCreatedViewState {
  valueCopied: boolean
}

const PatCreatedView: React.FC<PatCreatedViewProps & WithTranslation> = (props) => {
  let pre: any
  const [valueCopied, setValueCopied] = useState(false)


  const copyValueToClipboard = () => {
    const selection = window.getSelection()
    if (selection) {
      selection.removeAllRanges()
      var range = document.createRange()
      range.selectNode(pre)
      selection.addRange(range)
      document.execCommand("copy")
      selection.removeAllRanges()
      setValueCopied(true)
    }
  }

  const { t } = props
  return (
    <Modal
      maskClosable={false}
      visible
      closable={false}
      title={t('Personal Access Token (PAT) Created')}
      footer={[
        <Button key="submit" type="primary" onClick={() => props.onClose()}>
          {t('Ok')}
        </Button>,
      ]}
    >
      <p style={{ marginBottom: '14px' }}><b>{t('This is your newly created PAT. Save it carefuly since once you close this dialog, you will not see the token anymore')}</b></p>
      <div style={{ display: 'flex' }}>
        <pre ref={(p) => pre = p} style={{ border: '1px solid #d9d9d9', padding: '14px 11px', borderRadius: '2px', marginBottom: '0px' }}>{props.patValue ? props.patValue : '-'}</pre>
        <Tooltip title={t('Copy to clipboard')} overlayStyle={{ zIndex: 1000000 }}>
          <Button style={{ marginLeft: '5px' }} size="large" onClick={copyValueToClipboard} icon={<CopyOutlined />}>
          </Button>
        </Tooltip>
      </div>
      {
        valueCopied && <p style={{ color: '#0000a4' }}><b>{t('PAT copied to clipboard!')}</b></p>
      }
    </Modal>
  )
}

export default (withTranslation()(PatCreatedView) as any) as React.ComponentClass<PatCreatedViewProps>
