import { Modal, Spin } from 'antd'
import * as React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import HttpService from '../core/services/http.service'
import { container } from '../inversify.config'
import { IdentityProps, withIdentity } from '../core/services/authentication'
import { FC, useMemo, useState } from 'react'
import logo  from '../core/assets/logo.png'




export interface CustomLoadProps extends WithTranslation, IdentityProps {
  isLoading: boolean, 
  text : string
}

const CustomLoad: FC<CustomLoadProps> = ({
  isLoading,
  identity,
  t,
  text,
  children,
}) => {

  const [loading, setIsLoading] = useState<boolean>(isLoading)

  useMemo(() => {
    setIsLoading(isLoading)
  }, [isLoading]) // eslint-disable-line react-hooks/exhaustive-deps


  if (loading)
    return (
      <>
        <div
          className="customLoad"
        >
          <div style={{ textAlign: 'center', marginLeft : 55, display: 'inline' }}>
            <Spin style={{}} />{' '}
            <img style={{ background: 'rgb(0,0,164)', padding: '4px 4px 4px 4px', marginLeft: 10, }} alt="fi-logo" src={logo} height={34} />
            <span style={{ fontSize: 18, marginLeft: 10, color: '#0000a4' }}>
              {text}
            </span>
          </div>
        </div>
      </>
    )
    return <>{children}</>
  
}


export default withIdentity(withTranslation()(CustomLoad) as any)
