import React, { CSSProperties, FC, useEffect, useState } from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { container } from 'src/inversify.config'
import { Select, Spin, Tooltip } from 'antd'
import debounce from 'lodash/debounce'
import { Query } from 'src/core/stores/data-store'
import { TenantSummary, AssignedToStore } from 'src/stores/tenants/tenants-store'
import { ApartmentOutlined } from '@ant-design/icons'

const AsignedTo: FC<
  {
    excludeDescendand?: string
    onChange?: (value: { value: string; label: string }) => void
    placeholder?: string
    value?: { value: string; label: string }
    style?: CSSProperties
    loadComplete ? : () => void

  } & WithTranslation
> = ({ t, onChange, loadComplete, style, placeholder = t('Select entity'), value, excludeDescendand }) => {

  const entitiesStore = container.get<AssignedToStore>(AssignedToStore)

  const [currentValue, setValue] = useState(value)
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState<TenantSummary[]>([])

  const load = async (search: string | undefined = undefined) => {
    setLoading(true)
    const items = await entitiesStore.getItems({
      searchQuery: search,
      orderBy: [{ field: 'title', direction: 'Ascending', useProfile: false }],
      skip: 0,
      take: 10,
    } as Query)
    setOptions(items)
    if ( items.length > 0) {
      setValue({ value: items[0].id, label: items[0].title })
      if (onChange) onChange({ value: items[0].id, label: items[0].title })
    }
    if (loadComplete) loadComplete();
    setLoading(false)
  }

  useEffect(() => {
    let nextValue = value || currentValue
    if (nextValue) nextValue = undefined
  
    load()
    setValue(nextValue)
  }, [entitiesStore, value]) // eslint-disable-line react-hooks/exhaustive-deps


  const onSelect = (nextValue: { value: string; label: string }) => {
    setValue(nextValue)
    onChange && onChange(nextValue)
  }

  return (
    <Select
      loading={!!loading}
      labelInValue
      style={style}
      placeholder={placeholder}
      onChange={onSelect}
      showSearch
      value={currentValue}
      notFoundContent={!!loading ? <Spin size="small" /> : undefined}
      filterOption={false}
      showArrow={true}

    >
    
      {options.map((o: TenantSummary) => (
        <Select.Option key={o.id} value={o.id}>
          <Tooltip
            title={o.title} placement="leftTop"
            overlayStyle={{ zIndex: 9999 }}
            overlayInnerStyle={{ backgroundColor: 'white', color: 'black' }}>
            <ApartmentOutlined style={{ marginRight: 5 }} />
            {o.title || o.title.trim().length != 0 ? o.title : t("(Empty name)")}
          </Tooltip>
        </Select.Option>
      ))}
    </Select>
  )
}

export default withTranslation()(AsignedTo) as any
