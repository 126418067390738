import { Route, RouteComponentProps, withRouter } from 'react-router'
import { ConfigurationService, AppConfiguration } from 'src/core/services/configuration.service'
import SecureContent from 'src/core/services/authentication'
import { Layout } from 'antd'
import ProfileView from './profile-detail'
import { FC, useEffect, useRef, useState } from 'react'
import Shell from 'src/core/ui/shell'
import logo from 'src/core/assets/logo.png'
import footerLogo from 'src/core/assets/footer-logo.png'
import PrivacyRightsExcerciseView from './privacy-rights-excercise'
import ProfileClients from '../profile/my-clients'
import ServiceManager from '../profile/service-manager'
import { container } from '../../inversify.config'
import * as React from 'react'
import profileDisclaimers from './profile-disclaimers'
import ShellDisclaimer from '../../core/ui/shell-disclaimer'
import ShellNotification from '../../components/systemNotification/shell-notification'
import { withTranslation, WithTranslation } from 'react-i18next'
import { BankOutlined, ContactsOutlined, DownloadOutlined, FileOutlined, HomeOutlined, ProfileOutlined, UserOutlined } from '@ant-design/icons'

interface ProfileRoutesProps extends RouteComponentProps {

}

interface ProfileRoutesState {
  config: AppConfiguration | undefined
}

const HomeRoutes: FC<ProfileRoutesProps & WithTranslation> = (props) => {

  const configurationService = container.get(ConfigurationService)
  const [config, setConfig] = useState<AppConfiguration>()
  const inputRef = useRef()

  useEffect(
    () => {
      configurationService.current().then(x => { setConfig(x) })

    }, [inputRef]
  )


  const getMenu = () => {

    let menu = []

    menu.push({
      key: '0',
      icon: <HomeOutlined />,
      label: props.t('Home'),
      target: "/",
      exact: true
    })
    menu.push({
      key: '1',
      icon: <ProfileOutlined />,
      label: props.t('Profile'),
      target: "/profile",
      exact: true
    })
    menu.push({
      key: '2',
      icon: <BankOutlined />,
      label: props.t('Exercise rights'),
      target: "/profile/rights"
    })
    menu.push({
      key: '3',
      icon: <UserOutlined />,
      label: props.t('My Clients'),
      target: "/profile/clients",
      requiredRoles: ["Administrator", "IdentityConsultant"],
    })
    menu.push({
      key: '4',
      icon: <ContactsOutlined />,
      label: props.t('Service Manager'),
      target: "/profile/service-manager",
      requiredRoles: ["ServiceManager"],
    })

    menu.push({
      key: '5',
      icon: <FileOutlined />,
      label: props.t('Disclaimers'),
      target: "/profile/disclaimers"
    })


    return menu

  }
  const [menu] = useState(getMenu)

  if (config) {
    return <SecureContent
      config={{
        authority: config.identityServerUrl,
        redirectUri: config.serviceUrl,
        silentRedirectUri: `${config!.serviceUrl}/silentrefresh.html`,
        resource: config.scopes,
        clientId: config.clientId,
      }}
      onAuthentication={(auth) => {  window.history.replaceState(window.history.state, '', window.location.pathname + window.location.search) }}
    >
      <div>
        <ShellNotification>
          <Shell statusMode availableLanguages={['es', 'en', 'pt', 'fr', 'de', 'it']} className={config.environment} v2 sideMenu={{ menu: menu, collapsible: true, collapseDefaultValue: false }} logo={logo} footerLogo={footerLogo}>
            <Route exact path={`${props.match.path}`} component={ProfileView} />
            <Route exact path={`${props.match.path}/rights`} component={PrivacyRightsExcerciseView} />
            <Route exact path={`${props.match.path}/rigths`} component={PrivacyRightsExcerciseView} />
            <Route exact path={`${props.match.path}/clients`} component={ProfileClients} />
            <Route exact path={`${props.match.path}/service-manager`} component={ServiceManager} />
            <Route exact path={`${props.match.path}/disclaimers`} component={profileDisclaimers} />
          </Shell>
        </ShellNotification>
      </div>
    </SecureContent>
  } else {
    return <Layout>
      <div className="loaderContainer">
        <div className="loader">
          <div className="circle">&nbsp;</div>
          <div className="circle">&nbsp;</div>
          <div className="circle">&nbsp;</div>
          <div className="circle">&nbsp;</div>
        </div>
      </div>
    </Layout>
  }
}
export default withTranslation()(withRouter(HomeRoutes))