import React, { useState } from 'react'
import { Route, RouteComponentProps, withRouter, Switch } from 'react-router'
import Home from './pages/home'
import Login from './pages/account'
import Admin from './pages/admin'
import Profile from './pages/profile/index'
import { IdentityProps, withIdentity } from './core/services/authentication'
import error404 from 'src/core/ui/error/error404'

interface AppProps extends RouteComponentProps<any>, IdentityProps { }

const App: React.FC<AppProps> = ({ match, location, identity }) => {

  return (
    <Switch>
      <Route exact key={'home'} path={`/`} component={Home} />
      <Route key={'login'} path={`/account`} component={Login} />
      <Route key={'admin'} path={`/admin`} component={Admin} />
      <Route key={'profile2'} path={`/profile`} component={Profile} />
      <Route component={error404} />
    </Switch>
  )

}

export default withIdentity(withRouter(App))
