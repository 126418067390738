import * as React from 'react'
import { Form, Spin, Input, Checkbox, Modal, Row, Col, Alert } from 'antd'
let FormItem = Form.Item
import autobind from 'autobind-decorator'
import { GroupItemStore, NewGroupItem } from 'src/stores/groups/groups-store'
import { withTranslation, WithTranslation } from 'react-i18next'
import { nameof } from 'src/core/utils/object'
import TextArea from 'antd/lib/input/TextArea'
import { container } from '../../../inversify.config'
import AreaEditor from 'src/components/areaEdit'
import { useState } from 'react'

interface NewGroupViewProps {
  onClose: () => void
}

const NewGroupView: React.FC<NewGroupViewProps & WithTranslation> = (props) => {
  const [form] = Form.useForm<NewGroupItem>()
  const [initialValues, setInitialValues] = React.useState<NewGroupItem | undefined>({ description: "", id: "", name: "", areaId: "" })

  const currentStore = React.useMemo(() => container.get(GroupItemStore), [])
  const currentState = currentStore.state
  const [confirmLoading, setConfirmLoading] = useState(false);


  const onCancel = () => {
    props.onClose()
  }

  const onCreate = async () => {
    setConfirmLoading(true)
    let item: NewGroupItem
    try {
      item = (await form.validateFields()) as NewGroupItem
      item.areaId = (item.areaId as any).id || item.areaId
    } catch {
      setConfirmLoading(false)
      return
    }

    let response = await currentStore.create(item)
    if (response) {
      form.resetFields()
      props.onClose()
    }
    setConfirmLoading(false)
  }

  const { t } = props
  return (
    <Modal
      maskClosable={false}
      visible
      onCancel={() => onCancel()}
      onOk={() => onCreate()}
      closable={false}
      confirmLoading={confirmLoading}
      title={t('New group')}
      cancelText={t('Cancel')}
      okText={t('Ok')}
    >
      {currentState.errorMessage.get() && <Alert type="error" message={t(currentState.errorMessage.value || '')} />}
      <Spin spinning={currentState.isBusy.get()}>
        <Form id="modaForm" form={form} initialValues={initialValues}>
          <FormItem label={t("Name")}
            name={nameof<NewGroupItem>('name')}
            rules={[{ required: true, message: t('Please input your group name!') }]}>

            <Input placeholder={t("Name")} />
          </FormItem>
          <FormItem label={t("Description")}
            name={nameof<NewGroupItem>('description')}>

            <TextArea placeholder={t("Description")} />
          </FormItem>
          <FormItem label={t("Area")} name={nameof<NewGroupItem>('areaId')} rules={[{ required: true, message: t('Area required') }]}>
            <AreaEditor valueAsItemReference />
          </FormItem>
        </Form>
      </Spin>
    </Modal>
  )
}

// Wire up the React component to the Redux store
export default withTranslation()(NewGroupView) as any
